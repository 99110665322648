export interface SingleSearchIndex {
  readonly name: string
  readonly title: string
}

export const searchIndices: SingleSearchIndex[] = [
  {
    name: 'TestServices1',
    title: 'Services',
  },
  {
    name: 'TestTeam',
    title: 'Team',
  },
  {
    name: 'TestPosts',
    title: 'Posts',
  },
  {
    name: 'TestPodcasts',
    title: 'Podcasts',
  },
]
