import React, { useEffect, useLayoutEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { styled } from '@linaria/react'
import { useAnimateKeyframes } from 'react-simple-animate'
import { Logo } from './types'
import { hoverMedia } from '@/lib/theme'

interface Props {
  logos: Logo[]
}

export const LogoScroller = ({ logos }: Props) => {
  const [logosFinal, setLogosFinal] = useState<Logo[]>([])

  useLayoutEffect(() => {
    setLogosFinal(logos.concat(logos))
  }, [])

  const { play, style } = useAnimateKeyframes({
    iterationCount: 'infinite',
    duration: 120,
    keyframes: ['transform: translate3d(0, 0, 0)', 'transform: translate3d(-50%, 0, 0)'],
  })

  useEffect(() => {
    play(true)
  }, [])

  return (
    <Root>
      <Inner style={style}>
        {logosFinal.map(
          (logo, idx) =>
            logo.logo && (
              <LogoItem key={`${logo.contentfulid}_${idx}`} className={`logo$_${idx}`}>
                <GatsbyImage image={logo.logo.gatsbyImageData} alt={logo.contentfulid} loading="eager" />
              </LogoItem>
            ),
        )}
      </Inner>
    </Root>
  )
}

const Root = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  height: 90px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Inner = styled.div`
  height: 100%;
  display: flex;
  width: fit-content;
  transition: filter 0.2s ease-in-out;

  ${hoverMedia} {
    &:hover {
      -webkit-animation-play-state: paused !important;
      animation-play-state: paused !important;
      filter: unset;
    }
  }
`

const LogoItem = styled.div`
  height: 100%;
  display: inline-flex;
  margin-right: 48px;
  transition: filter 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  filter: grayscale(100%) brightness(120%);

  ${hoverMedia} {
    &:hover {
      filter: unset;
    }
  }
`
