import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { AllLinksWrapper, SingleLinkContent } from './AllLinksWrapper'

interface Data {
  allContentfulService: {
    nodes: {
      group: string
      serviceName: string
      serviceId: string
    }[]
  }
}

export const OurServices = () => {
  const data = useStaticQuery<Data>(graphql`
    query footerMenuQuery {
      allContentfulService(filter: { enabled: { eq: true }, moreButtonEnabled: { eq: true } }, sort: { order: ASC }) {
        nodes {
          group
          serviceName
          serviceId
        }
      }
    }
  `)

  const { nodes } = data.allContentfulService

  return (
    <AllLinksWrapper gridArea="services">
      <h2>Services</h2>
      {nodes.map((node, index) => (
        <Link key={index} to={`/services/${node.serviceId}/`}>
          <SingleLinkContent>{node.serviceName}</SingleLinkContent>
        </Link>
      ))}
    </AllLinksWrapper>
  )
}
