import React, { useEffect, useState } from 'react'
import { PopupWidget } from 'react-calendly'
import theme from '~/gatsby-plugin-theme-ui'

const BookAnAppointment = () => {
  const [rootElement, setRootElement] = useState(undefined)

  useEffect(() => {
    setRootElement(document.getElementById('___gatsby'))
  }, [])

  if (!rootElement) return null

  return (
    <div>
      <PopupWidget
        url="https://calendly.com/d/dvp-b5g-bdw"
        rootElement={rootElement}
        text="Click here to book a call"
        textColor={theme.colors.light}
        color={theme.colors.primary}
      />
    </div>
  )
}

export default BookAnAppointment
