import { useCallback, useEffect } from 'react'

export function useEscKeyHandler(clickHandlerCallback: () => void) {
  const keyPressHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation()
        e.preventDefault()
        clickHandlerCallback()
      }
    },
    [clickHandlerCallback],
  )

  useEffect(() => {
    document.addEventListener('keydown', keyPressHandler)

    return () => {
      document.removeEventListener('keydown', keyPressHandler)
    }
  }, [keyPressHandler])
}
