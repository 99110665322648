import React from 'react'
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { styled } from '@linaria/react'
//import { css } from '@linaria/core'
import Flickity from 'react-flickity-component'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import { theme } from '../gatsby-plugin-theme-ui/index'
//import { tabletMedia } from '~/lib/theme'
import '../flickity/flickity.css'

const LogoScroller2 = ({ logos, gridArea, className, autoplay = true }) => {
  /*
  const clientscroller = css`
    grid-area: clients;
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: [start] 4em [left] auto [right] 4em [end];
    grid-template-rows: [top] auto [bottom];
    justify-items: center;
    align-items: center;
    background-color: white;
    overflow-x: hidden;
    & button {
      background-color: ${theme.colors.deepAlpha40};
      transition: 0.3s all;
      cursor: pointer;
      border-radius: 50%;
      width: 3em;
      height: 3em;
      color: white;
    }
    & button:hover {
      background-color: ${theme.colors.logoOrange};
    }
  `
*/

  return (
    <LogoWrapper gridArea={gridArea} className={className}>
      <Flickity
        options={{
          freeScroll: true,
          //contain: true,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: autoplay,
          autoPlay: autoplay,
          initialIndex: Math.floor(logos.length / 2) - 0,
          //initialIndex: autoplay ? Math.floor(logos.length / 2) : null,
          //cellAlign: autoplay ? "center" : "left",
          selectedAttraction: 0.005,
        }}
      >
        {logos.map(logo => {
          return (
            <LogoItem key={logo.id}>
              {logo.url ? (
                <a href={logo.url} target="_blank" rel="noreferrer">
                  <GatsbyImage
                    key={logo.id}
                    image={getImage(logo.logo)}
                    alt={logo.contentfulid}
                    style={{
                      // filter: 'grayscale(100%)',
                      transition: '0.3s all',
                      '&:hover': { filter: 'none' },
                    }}
                  />
                </a>
              ) : (
                <GatsbyImage
                  key={logo.id}
                  image={getImage(logo.logo)}
                  alt={logo.contentfulid}
                  style={{
                    // filter: 'grayscale(100%)',
                    transition: '0.3s all',
                    '&:hover': { filter: 'none' },
                  }}
                />
              )}
            </LogoItem>
          )
        })}
      </Flickity>
    </LogoWrapper>
  )
}

const LogoItem = styled.div`
  padding: 16px 32px;
`

const LogoWrapper = styled.div`
  grid-area: ${props => props.gridArea};
`

export default LogoScroller2
