import React from 'react'
import { styled } from '@linaria/react'
import { IconSocial } from '@/UI'

interface Props {
  url: string
  title: string
}

export const ShareIcons = ({ url, title }: Props) => {
  return (
    <Container>
      <IconSocial type="Twitter" urlAdd={`intent/tweet?url=${url}&text=${title}`} />
      <IconSocial type="Facebook" urlAdd={`sharer/sharer.php?u=${url}`} />
      <IconSocial type="Mail" urlAdd={`subject=${title}&body=${title} (${url})`} />
      <IconSocial type="LinkedIn" urlAdd={`sharing/share-offsite/?url=${url}`} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  cursor: pointer;
`