import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { ContactForm } from './FormContent'
import { TitleH2, Typography, Wrapper } from '@/UI'
import { Styleable, WithChildrenOptional } from '@/lib/interfaces'

interface Props extends WithChildrenOptional, Styleable {
  title?: string
  description?: string
  messageHint?: string
}

export const ContactFormWithWrapper = ({ title, description, messageHint, children, className }: Props) => {
  return (
    <WrapperStyled $noMobilePadding $hasSideBlock={Boolean(children)} className={className}>
      <Container>
        {title && (
          <TitleH2 $isInverted $gap={0} $gapMob={0}>
            {title}
          </TitleH2>
        )}
        {description && <Typography dangerouslySetInnerHTML={{ __html: description }} $isInverse />}
        <ContactForm messageHint={messageHint} />
      </Container>
      {children}
    </WrapperStyled>
  )
}

const WrapperStyled = styled(Wrapper)<{ $hasSideBlock: boolean }>`
  grid-area: newForm;
  display: grid;

  ${desktopSmallMedia} {
    grid-column-gap: 48px;
    grid-template-columns: ${({ $hasSideBlock }) => ($hasSideBlock ? '58% auto' : '100%')};
  }
`

const Container = styled.div`
  display: grid;
  grid-gap: 32px;
  padding: 48px 24px;
  width: 100%;
  background-color: ${colors.secondary.secondary};

  ${desktopSmallMedia} {
    padding: 48px;
    border-radius: 16px;
  }
`
