import React from 'react'
import { styled } from '@linaria/react'
import { TagType } from '../../types'
import { tabletMedia } from '@/lib/theme'
import { tagState } from '../../state'
import { boxShadow } from '@/UI'

interface Props {
  tag: TagType
  isActive: boolean
}

export function Tag({ tag, isActive }: Props) {
  const clickHandler = () => {
    if (isActive) {
      tagState.selectedTag = null
      return
    }
    tagState.selectedTag = tag.tag
  }

  return (
    <Container className={boxShadow} isActive={isActive} onClick={clickHandler}>
      {tag.tag}
    </Container>
  )
}

const Container = styled.button<{ isActive: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 38px;
  border-radius: 6px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(39, 36, 36, 0.6);
  cursor: pointer;
  user-select: none;
  background: ${({ isActive }) => (isActive ? '#fff' : 'rgba(39, 36, 36, 0.1)')};
  transition-property: box-shadow, background;

  &:last-child {
    margin-right: 24px;

    ${tabletMedia} {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-left: 24px;

    ${tabletMedia} {
      margin-left: 0;
    }
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${tabletMedia} {
    margin-bottom: 8px;
  }
`
