/* eslint-disable no-param-reassign, no-useless-escape  */
import { ChangeEvent } from 'react'
import { proxy } from 'valtio'
import { FieldData, FieldId, SendingState } from './types'

export const formPrivacy = proxy({ agree: false })
export const togglePrivacyAgree = () => {
  formPrivacy.agree = !formPrivacy.agree
}

export const formNewsletterConsent = proxy({ agree: false })
export const toggleNewsletterConsent = () => {
  formNewsletterConsent.agree = !formNewsletterConsent.agree
}

const fieldsObject: Record<FieldId, FieldData> = {
  name: {
    id: 'name',
    type: 'text',
    autoComplete: 'name',
    value: '',
    wasError: false,
    inputState: null,
    get isValid() {
      return this.value.length >= 3 && this.value.length <= 50
    },
    get appearanceStatus() {
      return appearanceStatusHandler(this)
    },
  },
  email: {
    id: 'email',
    type: 'email',
    autoComplete: 'email',
    value: '',
    wasError: false,
    inputState: null,
    get isValid() {
      const matcherEmail: RegExp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.value.length || this.value.length > 320) return false
      return matcherEmail.test(this.value.toLowerCase())
    },
    get appearanceStatus() {
      return appearanceStatusHandler(this)
    },
  },
  message: {
    id: 'message',
    type: null,
    value: '',
    wasError: false,
    inputState: null,
    get isValid() {
      return this.value.length >= 3
    },
    get appearanceStatus() {
      return appearanceStatusHandler(this)
    },
  },
}

export const fieldsFabric = (fieldNames: FieldId[]) => {
  const fields: Record<FieldId, FieldData> = {
    name: proxy<FieldData>(fieldsObject.name),
    email: proxy<FieldData>(fieldsObject.email),
    message: proxy<FieldData>(fieldsObject.message),
  }

  const output: Partial<Record<FieldId, FieldData>> = {}

  fieldNames.forEach(fieldName => {
    output[fieldName] = fields[fieldName]
  })

  return output
}

export const fieldHandlersFabric = (field: FieldData) => {
  const setFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
    field.value = e.target.value
  }

  const onFocus = () => {
    field.inputState = 'isOnFocus'
  }

  const onBlur = () => {
    field.inputState = 'wasBlurred'
  }

  return {
    setFieldValue,
    onBlur,
    onFocus,
  }
}

export const fieldInTheForm: FieldId[] = ['name', 'email', 'message']

export const formFields = fieldsFabric(fieldInTheForm)

export const formDataState = proxy({
  ...formFields,
  formPrivacy,

  resetFields: () => {
    fieldInTheForm.forEach(fieldName => {
      formDataState[fieldName].value = ''
    })
    formDataState.formPrivacy.agree = false
  },

  get allTextFieldsAreValid(): boolean {
    const fieldsState: boolean[] = []
    fieldInTheForm.forEach(fieldName => fieldsState.push(this[fieldName].isValid))
    return fieldsState.every(Boolean)
  },

  get isValid(): boolean {
    return this.formPrivacy.agree && this.allTextFieldsAreValid
  },

  get formData() {
    const output = {}
    fieldInTheForm.forEach(fieldName => {
      output[fieldName] = this[fieldName].value
    })
    return output as Record<FieldId, string>
  },
})

export const sendingState = proxy<{ currentState: SendingState }>({ currentState: 'initial' })
export const setSendingState = (newState: SendingState) => {
  sendingState.currentState = newState
}

function appearanceStatusHandler(content: FieldData) {
  switch (true) {
    case !content.value.length:
      return 'initial'
    case content.inputState === 'wasBlurred' && !content.isValid:
    case content.inputState === 'isOnFocus' && !content.isValid && content.wasError:
      content.wasError = true
      return 'isError'
    case content.inputState === 'isOnFocus' && content.isValid && content.wasError:
      return 'isOk'
    case content.inputState === 'wasBlurred' && content.isValid:
      content.wasError = false
      return 'initial'
    default:
      return 'initial'
  }
}

export const customFormParams = proxy<{ messageHint: string | undefined }>({
  messageHint: undefined,
})
