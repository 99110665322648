import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Avatar = ({ ...props }) => {
  return (
    <GatsbyImage
      {...props}
      style={{
        p: 0,
        m: 0,
        borderRadius: '50%',
      }}
    />
  )
}

export default Avatar
