import React from 'react'
import { styled } from '@linaria/react'
import { Wrapper, TitleH2, Divider } from '@/UI'
import { desktopSmallMedia } from '@/lib/theme'
import { Blocks } from './components'

export const Cases = () => {
  return (
    <Root>
      <Inner $noMobilePadding={false}>
        <TitleH2 $gap={48}>Success Stories:</TitleH2>
        <Divider $customMargin={48} />
        <Blocks />
      </Inner>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 0;
  max-width: 480px;
  align-self: center;

  ${desktopSmallMedia} {
    margin: 64px 0;
    max-width: unset;
    align-self: unset;
  }
`

const Inner = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`
