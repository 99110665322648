import React from 'react'
import { graphql } from 'gatsby'
import { RichText2 } from '../components/richtext'
import { LightHero, Title } from '../components/hero'
import { SEO, Layout } from '@/blocks/layout'

const pageTitle = `404: Not Found`

const NotFoundPage = ({ data }) => {
  const { content } = data.snippet404

  return (
    <Layout hasNoVerticalMargins>
      <div
        style={{
          display: 'grid',
          gridTemplate: "'hero' 'snap'",
          gridTemplateColumns: '100%',
        }}
      >
        <LightHero style={{ gridArea: 'hero' }}>
          <Title>{pageTitle}</Title>
        </LightHero>
        <RichText2 content={content} style={{ gridArea: 'snap', maxWidth: '64em' }} />
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => {
  return <SEO title={pageTitle} />
}

export const pageQuery = graphql`
    query NotFoundQuery {
        snippet404: contentfulSnippet(contentfulid: { eq: "404" }) {
            content {
                raw
            }
        }
    }
`
