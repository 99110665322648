import React from 'react'
import HighlightGrid from '~/components/service/highlightgrid'

const Highlight = ({ children, style }) => {
  return (
    <HighlightGrid style={style}>
      <span>&#123;</span>
      {children}
    </HighlightGrid>
  )
}

export default Highlight