import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia,
  desktopLargeMedia,
} from '@/lib/theme'

const SectionWithHighlight = styled.div`
  display: grid;
  grid-template: 'heading' 'body' 'highlight';
  width: 100%;
  padding: 32px 24px;
  gap: 32px;
  ${desktopSmallMedia} {
    grid-template: 'heading highlight' 'body highlight';
    grid-template-columns: 2fr 1fr;
  }
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`

export default SectionWithHighlight