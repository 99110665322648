import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, fontLeagueSpartan, fontLibreBaskerville, mobileModernMedia } from '@/lib/theme'

import { Factoid } from '../types'

const pieceColors: { fill: string; text: string }[] = [
  { fill: colors.white, text: colors.secondary.secondary },
  { fill: colors.tertiary.lightest, text: colors.secondary.secondary },
  { fill: colors.tertiary.darkest, text: colors.textStyles.inverse },
  { fill: colors.secondary.secondary, text: colors.textStyles.inverse },
]

interface Props extends Factoid {
  index: number
}

export const Piece = ({ text, figure, index }: Props) => {
  const divider = pieceColors.length
  const currentIndex = index % divider

  return (
    <Root $currentIndex={currentIndex}>
      <Figure>{figure}</Figure>
      <Text>{text}</Text>
    </Root>
  )
}

const Root = styled.div<{ $currentIndex: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 32px;
  position: relative;
  background-color: ${({ $currentIndex }) => pieceColors[$currentIndex].fill};
  color: ${({ $currentIndex }) => pieceColors[$currentIndex].text};
  margin: -12px;
  width: 155px;
  height: 155px;

  ${mobileModernMedia} {
    width: 176px;
    height: 176px;
  }

  ${desktopSmallMedia} {
    margin: -16px;
    width: 200px;
    height: 200px;
  }
`
const Figure = styled.span`
  font-family: ${fontLeagueSpartan};
  text-align: center;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-size: 40px;
  max-width: 120px;

  ${desktopSmallMedia} {
    max-width: 132px;
    font-size: 48px;
  }
`

const Text = styled.span`
  text-align: center;
  font-family: ${fontLibreBaskerville};
  font-style: italic;
  font-weight: 400;
  line-height: 1.22;
  letter-spacing: -0.01em;
  font-size: 16px;
  max-width: 120px;

  ${desktopSmallMedia} {
    font-size: 18px;
    max-width: 132px;
  }
`
