import React, { ReactNode, useCallback } from 'react'
import { styled } from '@linaria/react'
import { Portal } from './Portal'
import { useEscKeyHandler } from '../hooks'

type Event = React.MouseEvent | React.TouchEvent

interface Props {
  zIndex?: number
  isNotDark?: boolean
  children: ReactNode
  onFadeClick?: () => void
}

export const Fade = ({ children, onFadeClick, isNotDark, zIndex }: Props) => {
  useEscKeyHandler(onFadeClick)

  const fadeClickHandler = useCallback(
    (e: Event) => {
      e.stopPropagation()
      onFadeClick()
    },
    [onFadeClick]
  )

  return (
    <Portal zIndex={zIndex}>
      <Container onClick={fadeClickHandler} isNotDark={isNotDark}>
        {children}
      </Container>
    </Portal>
  )
}

const Container = styled.div<{ isNotDark: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isNotDark }) => (isNotDark ? 'transparent' : 'rgba(0, 0, 0, 0.7)')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  cursor: pointer;
`
