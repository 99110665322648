export const maxLinesInBlock = (linesCount?: number) => {
  const styles = {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',

    '& *': {
      display: 'inline',
    },
  }

  if (typeof linesCount === 'number' && !Number.isNaN(linesCount) && linesCount >= 1) {
    styles['-webkit-line-clamp'] = `${linesCount}`
  }

  return styles
}
