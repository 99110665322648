import { useStaticQuery, graphql } from 'gatsby'

const schemaOrgLogoObject = () => {
  const obj = {
    '@type': 'ImageObject',
    url: 'https://samexpert.com/SAMexpert-logo.svg',
    name: 'SAMexpert logo',
  }

  return obj
}

const SchemaOrgOrganizationObject = siteMetaData => {
  const data = useStaticQuery(graphql`
    query orgLocationsQuery {
      locations: allContentfulLocation(sort: {index: ASC}) {
        nodes {
          addressCountry
          addressLocality
          addressRegion
          postalCode
          streetAddress
          telephone
        }
      }
      founders: allContentfulAuthor(filter: {founder: {eq: true}}, sort: {order: ASC}) {
        nodes {
          name
          slug
          bio {
            childMarkdownRemark {
              excerpt
            }
          }
          email
        }
      }
    }
  `)

  const locations = data.locations.nodes.map(loc => {
    return { ...loc, '@type': 'PostalAddress' }
  })

  const founders = data.founders.nodes.map(person => {
    return {
      '@type': 'Person',
      name: person.name,
      url: `https://samexpert.com/team/${person.slug}/`,
      jobTitle: person.bio.childMarkdownRemark.excerpt,
      email: person.email,
    }
  })

  const org = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    email: siteMetaData.email,
    url: siteMetaData.siteUrl,
    name: siteMetaData.title,
    description: siteMetaData.description,
    logo: schemaOrgLogoObject(),
    address: locations[0],
    telephone: locations[0].telephone,
    location: locations,
    founder: founders,
    //legalName: "ITAMIN LTD",
  }
  return org
}

export const schemaOrgOrganizationShortObject = siteMetaData => {
  const org = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    email: siteMetaData.email,
    url: siteMetaData.siteUrl,
    name: siteMetaData.title,
    description: siteMetaData.description,
    logo: schemaOrgLogoObject(),
  }
  return org
}

export const schemaOrgOrganization = siteMetaData => {
  return JSON.stringify(SchemaOrgOrganizationObject(siteMetaData))
}

export const schemaOrgLogo = () => {
  return JSON.stringify(schemaOrgLogoObject())
}

const schemaOrgVideoObject = video => {
  return {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    '@id': `https://www.youtube.com/watch?v=${video.videoId}`,
    name: video.title,
    uploadDate: video.publishedAt,
    datePublished: video.publishedAt,
    thumbnailUrl: video.thumbnail.url,
    description: video.description,
    embedUrl: `https://www.youtube.com/watch?v=${video.videoId}`,
    duration: video.duration,
    interactionStatistic: [
      {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'WatchAction' },
        userInteractionCount: video.viewCount,
      },
      {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'LikeAction' },
        userInteractionCount: video.likeCount,
      },
      {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'CommentAction' },
        userInteractionCount: video.commentCount,
      },
    ],
  }
}

export const schemaOrgVideo = video => {
  return JSON.stringify(schemaOrgVideoObject(video))
}

const schemaOrgFaqObject = questions => {
  //console.log(questions)

  const qa = questions.map(question => {
    return {
      '@type': 'Question',
      name: question.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: question.answer,
      },
    }
  })

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: qa,
  }
}

export const schemaOrgFaq = questions => {
  if (!questions) return null
  return JSON.stringify(schemaOrgFaqObject(questions))
}
