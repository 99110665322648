import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { graphql, useStaticQuery } from 'gatsby'
import { RichTextImproved } from '@/blocks/rich-text-improoved'
import { Wrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { ContactFormWithWrapper, SideBlock } from '@/blocks/contact-form'
import { Locations } from '~/features/info/contact/Locations'

export const Contact = () => {
  const data = useStaticQuery<Queries.contactsDataQuery>(graphql`
    query contactsData {
      snippetTitleBlock: contentfulSnippet(contentfulid: { eq: "contactpage-top-title-block" }) {
        content {
          raw
        }
      }
      snippetBookACall: contentfulSnippet(contentfulid: { eq: "contactpage-middle-book-a-call" }) {
        content {
          raw
        }
      }
      snippetAfter: contentfulSnippet(contentfulid: { eq: "contactpage-bottom" }) {
        content {
          raw
        }
      }
    }
  `)

  const { snippetBookACall, snippetAfter, snippetTitleBlock } = data

  return (
    <ContactView>
      <WrapperStyled>
        <RichTextImproved content={snippetTitleBlock.content} />
      </WrapperStyled>
      <ContactFormWithWrapper
        className={FormWrapperStyles}
        title="Send us a message"
        messageHint="Describe your challenges"
      >
        <SideBlocksWrapper>
          <SideBlock isNoBgOnMobile content={snippetBookACall.content} />
          <Locations />
        </SideBlocksWrapper>
      </ContactFormWithWrapper>
      <WrapperBottomTexts>
        <BottomTexts content={snippetAfter.content} />
      </WrapperBottomTexts>
    </ContactView>
  )
}

const ContactView = styled.div`
  display: flex;
  flex-direction: column;
`

const WrapperStyled = styled(Wrapper)`
  margin-top: 48px;
  margin-bottom: 48px;
  display: grid;
  grid-row-gap: 24px;

  ${desktopSmallMedia} {
    margin-top: 64px;
    margin-bottom: 56px;
  }
`

const FormWrapperStyles = css`
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: ${colors.background.grayLightest};

  ${desktopSmallMedia} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const WrapperBottomTexts = styled(Wrapper)`
  margin-top: 48px;
  margin-bottom: 48px;

  ${desktopSmallMedia} {
    margin-top: 64px;
    margin-bottom: 64px;
  }
`

const BottomTexts = styled(RichTextImproved)``

const SideBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
`
