import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Layout, SEO } from '@/blocks/layout'
import { SingleEpisode, EpisodeSinglePage, getJsonLd } from '~/features/podcast'

export { globals } from '@/lib/global-styles'

const PodcastPage = ({ data }: PageProps<Queries.ContentfulPodcastEpisodeBySlugQuery>) => {
  return (
    <Layout breadcrumbsLink="/podcast" breadcrumbsText="all episodes" hasNoVerticalMargins>
      <SingleEpisode data={data.contentfulPodcastEpisode as unknown as EpisodeSinglePage} />
    </Layout>
  )
}

export default PodcastPage

export const Head = ({ data, location }: PageProps<Queries.ContentfulPodcastEpisodeBySlugQuery>) => {
  const { title, metaDescription, image, pubDate, file, seconds, podcast, description, hosts } =
    data.contentfulPodcastEpisode

  const jsonLdObj = getJsonLd({
    title,
    description,
    pubDate,
    url: location.pathname,
    contentUrl: `${podcast.cdnFolder}${file}`,
    duration: seconds,
    hosts,
  })

  return (
    <SEO
      title={title}
      description={metaDescription}
      image={image.gatsbyImageData.images.fallback.src}
      richresult={JSON.stringify(jsonLdObj)}
    />
  )
}

export const pageQuery = graphql`
  query ContentfulPodcastEpisodeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPodcastEpisode(id: { eq: $slug }) {
      id
      title
      metaDescription: shortDescription
      description {
        raw
      }
      pubDate
      bytes
      seconds
      file
      image {
        gatsbyImageData
        title
      }
      transcript {
        raw
        references {
          ... on ContentfulAsset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            contentful_id
            title
          }
        }
      }
      podcast {
        cdnFolder
      }
      hosts {
        avatar {
          gatsbyImageData(height: 100, formats: AUTO, quality: 10, width: 100)
        }
        slug
        name
      }
    }
  }
`
