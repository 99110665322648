import React from 'react'
import { styled } from '@linaria/react'
import { graphql, useStaticQuery } from 'gatsby'
import { HighlightMain, HighlightsSecondary } from './components'
import { colors, desktopSmallMedia } from '@/lib/theme'

export const Highlights = () => {
  const data = useStaticQuery<Queries.homepageHighlightsQueryQuery>(graphql`
    query homepageHighlightsQuery {
      allContentfulBanner(sort: { weight: DESC }, filter: { visible: { ne: false } }, limit: 5) {
        nodes {
          coverImage {
            gatsbyImageData
          }
          contentful_id
          bigIcon: coverImage {
            gatsbyImageData(width: 784, formats: [AUTO, WEBP, AVIF], quality: 20)
          }
          icon {
            gatsbyImageData(width: 256, formats: [AUTO, WEBP, AVIF], quality: 20)
          }
          subtitle
          title
          url
        }
      }
    }
  `)
  const highlights = data.allContentfulBanner.nodes

  return (
    <Root>
      <HighlightMain data={highlights[0]} />
      {highlights.length > 1 && <HighlightsSecondary data={highlights.slice(1)} />}
      <Barrier />
    </Root>
  )
}

const Root = styled.div`
  display: grid;
`

const Barrier = styled.div`
  width: 100%;
  background-color: ${colors.secondary.secondary};
  height: 32px;

  ${desktopSmallMedia} {
    height: 48px;
  }
`
