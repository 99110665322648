import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia, //desktopLargeMedia
} from '@/lib/theme'

const StepNumber = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  border: 3px solid black;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  height: 48px;
  width: 48px;
  grid-area: ${props => 1 + 4 * props.index} / 1 / ${props => 3 + 4 * props.index} / 3;
  ${desktopSmallMedia} {
    grid-area: 1 / ${props => 2 + 5 * props.index} / 3 / ${props => 4 + 5 * props.index};
  }
`

export default StepNumber
