import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, fontLibreBaskerville } from '@/lib/theme'

type TextLinkSize = 'small' | 'medium' | 'big'

const fontSizes: Record<TextLinkSize, number> = {
  big: 24,
  medium: 18,
  small: 16,
}

const dashSize = '4px'

export const TextLink = styled.span<{ $size?: TextLinkSize; $sizeMobile?: TextLinkSize }>`
  color: ${colors.primary.primary};
  font-family: ${fontLibreBaskerville};
  font-style: italic;
  font-weight: 400;
  line-height: 1.35;
  text-align: center;
  letter-spacing: -0.01em;
  padding-bottom: 4px;
  position: relative;
  font-size: ${({ $sizeMobile, $size = 'small' }) => fontSizes[$sizeMobile ?? $size]}px;

  ${desktopSmallMedia} {
    font-size: ${({ $size = 'small' }) => fontSizes[$size]}px;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: radial-gradient(circle closest-side, ${colors.primary.primary} 9%, transparent) 0 0 / ${dashSize} 100%,
      linear-gradient(90deg, ${colors.primary.primary} 50%, transparent 0) calc(${dashSize} / 2) 0 /
        calc(2 * ${dashSize}) 100%;
  }
`
