import React from 'react'
import { styled } from '@linaria/react'
import { graphql, useStaticQuery } from 'gatsby'
import { useSnapshot } from 'valtio'
import { tabletMedia } from '@/lib/theme'
import { Wrapper } from '@/UI'
import { Tag } from './Tag'
import { tagState } from '../../state'

export const Tags = () => {
  const data = useStaticQuery<Queries.blogTagsQueryQuery>(graphql`
    query blogTagsQuery {
      allContentfulTag(sort: { totalPosts: DESC }, filter: { totalPosts: { gt: 3 } }) {
        edges {
          node {
            id
            tag
            slug
            totalPosts
          }
        }
      }
    }
  `)
  const tags = data?.allContentfulTag?.edges
  const hasTags = Array.isArray(tags)
  const { selectedTag } = useSnapshot(tagState)

  if (!hasTags) return null

  return (
    <Container $noMobilePadding>
      {tags.map(({ node }) => (
        <Tag key={node.id} tag={node} isActive={selectedTag === node.tag} />
      ))}
    </Container>
  )
}

const Container = styled(Wrapper)`
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${tabletMedia} {
    overflow-x: initial;
    overflow-y: initial;
    flex-wrap: wrap;
    margin-bottom: -8px;
  }
`
