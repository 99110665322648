import React from 'react'
import { styled } from '@linaria/react'
import { cx } from '@linaria/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { VideoType } from './types'
import { AspectRatioContainer, selectVideo, boxShadow } from '@/UI'

interface Props {
  picture: VideoType['localThumbnail']
  alt: string
  isInteractive?: boolean
  videoId?: string
  className?: string
}

export const VideoThumbnail = ({ picture, alt, isInteractive, videoId, className }: Props) => {
  const thumbnail = getImage(picture)
  const thumbnailIsClickable = isInteractive && Boolean(videoId)
  const clickHandler = thumbnailIsClickable ? () => selectVideo(videoId) : null

  const { allFile } = useStaticQuery<Queries.Query>(
    graphql`
      query {
        allFile(filter: { extension: { eq: "png" }, name: { eq: "play" } }) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `,
  )
  const pseudoBtnUrl = allFile.edges[0]?.node.publicURL

  return (
    <AspectRatioContainer
      verticalRatio="56%"
      onClick={clickHandler}
      className={cx(Boolean(className) && className, thumbnailIsClickable && boxShadow)}
    >
      <GatsbyImage alt={alt} image={thumbnail} />
      <PseudoButtonContainer>
        <PseudoBtn alt="Watch me" src={pseudoBtnUrl} />
      </PseudoButtonContainer>
    </AspectRatioContainer>
  )
}

const PseudoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
`

const PseudoBtn = styled.img`
  width: 100px;
  height: 100px;
`
