import { RefObject, useEffect } from 'react'

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: ((e: MouseEvent | TouchEvent) => void) | undefined
) {
  useEffect(() => {
    const el = ref?.current

    if (!el || handler == null) return

    const listener = (e: MouseEvent | TouchEvent) => {
      if (!el || el.contains(e.target as Element))  return

      handler(e)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [handler, ref])
}
