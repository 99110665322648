import { styled } from '@linaria/react'
import { desktopLargeMedia, desktopSmallMedia, tabletMedia } from '@/lib/theme'
import { Wrapper } from '@/UI'

export const Grid = styled.div`
  --columns-count: 1;
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(var(--columns-count), 1fr);

  ${tabletMedia} {
    --columns-count: 2;
  }

  ${desktopSmallMedia} {
    --columns-count: 3;
  }

  ${desktopLargeMedia} {
    --columns-count: 4;
  }
`

export const SectionWrapper = styled(Wrapper)`
  display: grid;
  grid-row-gap: 24px;
`
