import { styled } from '@linaria/react'

export const AspectRatioContainer = styled.div<{ verticalRatio?: string }>`
  width: 100%;
  position: relative;
  flex-shrink: 0;

  &:after {
    display: block;
    content: '';
    padding-bottom: ${({ verticalRatio }) => (verticalRatio || '100%')};
  }

  & > * {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`
