import React, { useLayoutEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Wrapper } from '@/UI'
import { MainEpisode, ServicesBlock, RegularEpisodesList, ContentWrapper, stopPlay } from './components'

export const AllEpisodes = () => {
  const data = useStaticQuery<Queries.podcastRSSQueryQuery>(graphql`
    query podcastRSSQuery {
      allContentfulPodcastEpisode(sort: { pubDate: DESC }) {
        nodes {
          file
          id
          bytes
          image {
            gatsbyImageData
            title
          }
          description {
            raw
          }
          shortDescription
          pubDate
          seconds
          title
          podcast {
            author
            category
            cdnFolder
            copyright
            description
            language
            ownerEmail
            ownerName
            title
            image {
              gatsbyImageData
              title
            }
            id
          }
        }
      }
    }
  `)
  const episodes = data?.allContentfulPodcastEpisode?.nodes

  const dataSafe = Array.isArray(episodes) ? episodes : []
  const mainEpisodeData = dataSafe[0]
  const restOfEpisodesData = dataSafe.slice(1)

  useLayoutEffect(() => {
    return () => {
      stopPlay()
    }
  }, [])

  return (
      <ContentWrapper>
        {mainEpisodeData && <MainEpisode data={mainEpisodeData} />}
        <ServicesBlock />
        {restOfEpisodesData.length && (
          <Wrapper $noMobilePadding={false}>
            <RegularEpisodesList data={restOfEpisodesData} />
          </Wrapper>
        )}
      </ContentWrapper>
  )
}
