import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'

export const Divider = styled.div<{ $customMargin?: number; $customMarginMob?: number; }>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ $customMarginMob }) => $customMarginMob ?? 32}px;

  ${desktopSmallMedia} {
    margin-bottom: ${({ $customMargin }) => $customMargin ?? 64}px;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    background-color: ${colors.tertiary.tertiary};
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.5;
  }
`
