import React, { Fragment, useCallback, useRef } from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { MenuItemType } from '../../../types'
import { ExternalLink } from '@/UI'
import { colors, hoverMedia, theme } from '@/lib/theme'
import { hideDropdown } from '@/blocks/layout/partials/header/state'

interface Props {
  submenus: MenuItemType[]
}

export const Dropdown = ({ submenus }: Props) => {
  const ref = useRef(null)

  const hideDropdownHandler = useCallback(() => {
    setTimeout(hideDropdown, 100)
  }, [])

  if (!Array.isArray(submenus) || !submenus.length) return null

  return (
    <Root itemsCount={submenus.length} ref={ref}>
      {submenus.map(({ url, title, external }) => (
        <Fragment key={`${url}_${title}`}>
          {!external ? (
            <Link to={url} onClick={hideDropdownHandler}>
              <Item>{title}</Item>
            </Link>
          ) : (
            <LinkExt href={url}>
              <Item>
                {title}
                <ExternalLink />
              </Item>
            </LinkExt>
          )}
        </Fragment>
      ))}
    </Root>
  )
}

const LinkExt = styled.a`
  display: flex;
  align-items: center;
`

const Item = styled.li`
  user-select: none;
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  ${hoverMedia} {
    &:hover {
      color: ${theme.hover};
    }
  }
`

const Root = styled.ul<{ itemsCount: number }>`
  color: ${colors.secondary.secondary};
  transition: all 0.1s ease-in-out;
  left: -24px;
  position: absolute;
  white-space: nowrap;
  z-index: 9999;
  padding: 16px 24px 24px 24px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${({ itemsCount }) => itemsCount}, 1fr);
  box-shadow: 0 0 8px 2px rgba(39, 36, 36, 0.30);
  display: grid;
  background-color: ${colors.white};
  border-radius: 0 0 8px 8px;
}
`
