import { useCallback } from 'react'

export function useBookACall(url?: string) {
  const urlFinal = url ?? 'https://calendly.com/d/dvp-b5g-bdw'

  return useCallback(() => {
    if (typeof window === 'undefined') return

    window.open(urlFinal, '_blank', 'noopener,noreferrer')
  }, [urlFinal])
}
