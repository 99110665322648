export const theme = {
  useColorSchemeMediaQuery: false,
  useLocalStorage: false,
  //useCustomProperties: false,
  //breakpoints: ['30rem', '44rem', '66rem', '88rem', '110rem'],
  breakpoints: ["30rem", "44rem", "60rem", "88rem", "110rem"],
  space: [
    0,
    ".25rem",
    ".5rem",
    "1rem",
    "2rem",
    "4rem",
    "8rem",
    "16rem",
    "32rem",
    "48rem",
    "96rem",
  ],
  sizes: [
    0,
    ".25rem",
    ".5rem",
    "1rem",
    "2rem",
    "4rem",
    "8rem",
    "16rem",
    "32rem",
    "48rem",
    "96rem",
  ],
  fonts: {
    body: '"Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: "inherit",
    monospace: "Menlo, monospace",
    condensed:
      '"Roboto Condensed", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  fontSizes: [
    ".8rem",
    ".9rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.75rem",
    "2rem",
    "2.5rem",
    "3rem",
    "4rem",
    "6rem",
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    // standard names for Theme UI, use for <main>
    // text: "#252422", // darkest
    // background: "#FFFCF2", // lightest
    text: "black", // since June 2022
    background: "white", // since June 2022
    primary: "#8C271E", // crimson
    secondary: "#EB5E28", // flame
    muted: "#8A817C", // medium-grey

    // my own names
    navback: "#403D39", // deep
    navbacksocial: "#252422", // darkest
    navbacktransparent: "rgba(64, 61, 57, .95)", // for transparency
    navlink: "#CCC5B9", // pale, inactive
    navhover: "#EB5E28", // flame
    // navactive: "#FFFCF2", // lightest
    navactive: "white", // since June 2022
    heading: "#252422", // dark

    // palette
    dark: "#252422", // article text
    darkAlpha: "rgba(37, 36, 34, .5)",
    darkAlpha40: "rgba(37, 36, 34, .4)",
    darkAlpha60: "rgba(37, 36, 34, .6)",
    darkAlpha80: "rgba(37, 36, 34, .8)",
    darkAlpha90: "rgba(37, 36, 34, .9)",

    deep: "#403D39",
    deepAlpha: "rgba(64, 61, 57, .5)",
    deepAlpha30: "rgba(64, 61, 57, .3)",
    deepAlpha40: "rgba(64, 61, 57, .4)",
    deepAlpha50: "rgba(64, 61, 57, .5)",
    deepAlpha70: "rgba(64, 61, 57, .7)",
    deepAlpha90: "rgba(64, 61, 57, .9)",

    medium: "#8A817C",
    pale: "#CCC5B9",
    //light: "#FFFCF2", // artcle background
    //lightAlpha0: "rgba(255, 252, 242, 0)", // for gradients
    light: "white", // artcle background since June 2022
    lightAlpha0: "rgba(255, 255, 255, 0)", // for gradients

    orange: "#FFC15E",
    flame: "#EB5E28",
    crimson: "#8C271E",
    // outside
    logoOrange: "#FF7F50",
    logoAlpha50: "rgba(255, 127, 80, .5)",
    logoAlpha30: "rgba(255, 127, 80, .3)",
    logoAlpha20: "rgba(255, 127, 80, .2)",
    logoAlpha10: "rgba(255, 127, 80, .1)",
    // /palette

    /* Will add dark mode later
    modes: {
      dark: {
        text: '#E6E6E6',
        background: '#4a4a4a',
      },
    }
    */
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mt: 0,
      mb: ".5rem",
    },
    paragraph: {
      mt: 0,
      mb: "1rem",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      height: "100%",
    },
    h1: {
      variant: "text.heading",
      fontSize: [5, 6, 7],
    },
    h2: {
      variant: "text.heading",
      fontSize: [4, 5, 6],
    },
    h3: {
      variant: "text.heading",
      fontSize: [3, 4, 5],
    },
    h4: {
      variant: "text.heading",
      fontSize: [2, 3, 4],
    },
    h5: {
      variant: "text.heading",
      fontSize: [1, 2, 3],
    },
    h6: {
      variant: "text.heading",
      fontSize: [0, 1, 2],
    },
    p: {
      variant: "text.body",
      fontSize: [2, 3],
    },
    a: {
      color: "secondary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
  buttons: {
    primary: {
      bg: "primary",
    },
    secondary: {
      bg: "secondary",
    },
    muted: {
      bg: "muted",
    },
  },
  globals: {
    cardBorderRadius: 0,
    formBorderRadius: 0,
  },
}

export default theme
