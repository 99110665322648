import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { useGetSiteMetadata } from '@/hooks'

export const Copyright = () => {
  const { trademark } = useGetSiteMetadata()
  const currentYear = new Date().getFullYear()

  return (
    <Container>
      <b>
        <Link to="/copyright/">
          &copy; {currentYear} {trademark}
        </Link>
      </b>{' '}
      &middot; <Link to="/sitemap/">Sitemap</Link> &middot; <Link to="/cookies/">Cookies</Link> &middot;{' '}
      <Link to="/privacy/">Privacy policy</Link> &middot; <Link to="/legal/">Legal</Link>
    </Container>
  )
}

const Container = styled.nav`
  grid-area: copyright;
  //justify-self: center;

  & a {
    min-width: 48px;
  }
`
