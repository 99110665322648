import React from 'react'
import { css, cx } from '@linaria/core'
import { Button } from '@/UI'
import { desktopSmallMedia } from '@/lib/theme'
import { useGoToContacts } from '../../hooks/useGoToContacts'

type ButtonType = 'onHeader' | 'intoMenu'

interface Props {
  className?: string
  buttonType: ButtonType
}

export const ButtonContactCommon = ({ className, buttonType }: Props) => {
  const clickHandler = useGoToContacts()

  return (
    <Button
      rippleDuration="0.3s"
      onClick={clickHandler}
      type="dark"
      size="small"
      className={cx(
        className,
        buttonType === 'onHeader' && ButtonStylesHeader,
        buttonType === 'intoMenu' && ButtonStylesMenu,
      )}
    >
      Contact
    </Button>
  )
}

const ButtonStylesHeader = css`
  margin-left: auto;
  display: none;

  ${desktopSmallMedia} {
    display: flex;
  }
`

const ButtonStylesMenu = css`
  width: 100%;
`
