import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, media, tabletMedia, theme } from '@/lib/theme'
import {
  Copyright, // Award,
  Pages,
  Insights,
  FooterSocial,
  OurServices,
  Resources,
} from './components'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

export const Footer = () => {
  return (
    <Container>
      <FooterGrid>
        <FooterSocial />
        <OurServices />
        <Pages />
        <Insights />
        <Copyright />
        <Resources />
      </FooterGrid>
    </Container>
  )
}

const Container = styled.footer`
  display: grid;
  justify-items: center;
  width: 100%;
  background-color: black;
  background: radial-gradient(at top, #333, black);
  ${doNotPrintThisBlock};
`

const FooterGrid = styled.div`
  padding: 48px 24px 48px 24px;
  grid-row-gap: 3em;
  grid-column-gap: 4em;
  display: grid;
  align-items: start;
  grid-template: 'services' 'pages' 'insights' 'resources' 'social' 'copyright';

  ${tabletMedia} {
    grid-template: 'social services services' 'pages insights resources' 'copyright copyright copyright';
    grid-template-columns: repeat(3, 1fr);
  }

  ${desktopSmallMedia} {
    padding-top: 64px;
    padding-bottom: 128px;
    grid-template: 'social services services' 'pages insights resources' 'copyright copyright copyright';
    grid-template-columns: repeat(3, 1fr);
  }

  & h2 {
    color: white;
    margin: 0;
    font-size: 1em;
    justify-self: start;
    font-weight: 700;
  }

  & a {
    color: ${colors.textStyles.inverseTransparent};
    text-decoration: none;
    font-size: 1em;
    justify-self: start;
    transition: 0.3s;
    white-space: nowrap;
  }

  ${media.hover} {
    & a:hover {
      color: ${theme.hover};
    }
  }
`
