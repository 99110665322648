import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { styled } from '@linaria/react'
import { desktopLargeMedia, desktopSmallMedia } from '@/lib/theme'
import { Block } from './Block'

export const Blocks = () => {
  const data = useStaticQuery<Queries.homepageCasesQuery>(graphql`
    query homepageCases {
      allContentfulCase(filter: { showOnHomepage: { eq: true } }, limit: 4) {
        nodes {
          title
          homepageText {
            raw
          }
          id
        }
      }
    }
  `)

  return (
    <Root>
      {data.allContentfulCase.nodes.map(({ title, homepageText, id }) => (
        <Block key={id} title={title} text={homepageText} />
      ))}
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  grid-gap: 32px;

  ${desktopSmallMedia} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px;
  }

  ${desktopLargeMedia} {
    grid-template-columns: repeat(4, 1fr);
  }
`
