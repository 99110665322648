import React from 'react'
import { styled } from '@linaria/react'
import { graphql, useStaticQuery } from 'gatsby'
import { desktopSmallMedia } from '@/lib/theme'
import { Divider } from '@/UI'
import { LogoScroller } from './LogoScroller'
import { Logo } from './types'

export const OurClients = () => {
  const data = useStaticQuery(graphql`
    query OurClientsQuery {
      snippetOurClients: contentfulSnippet(contentfulid: { eq: "ourclients" }) {
        keyWord
        title
        content {
          raw
        }
      }
      homePageLogos: allContentfulLogo(
        sort: [{ order: ASC }, { contentfulid: ASC }]
        filter: { okOnCompanyPage: { eq: true } }
      ) {
        nodes {
          id
          contentfulid
          logo {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              height: 90
              resizingBehavior: SCALE
              layout: FIXED
            )
          }
        }
      }
    }
  `)

  return (
    <Root>
      <Divider />
      <LogoScroller logos={data.homePageLogos.nodes as Logo[]} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  ${desktopSmallMedia} {
    margin-bottom: 64px;
  }
`
