import { graphql, useStaticQuery } from 'gatsby'
import { MenuItemType } from '../../types'


interface Data {
  allContentfulService: {
    nodes: {
      group: string
      serviceName: string
      serviceId: string
    }[]
  }
}

export function useGetMenuItems() {
  const data = useStaticQuery<Data>(graphql`
    query headerMenuQuery {
      allContentfulService(
        filter: { enabled: { eq: true }, moreButtonEnabled: { eq: true } }
        sort: [{order: ASC}]
      ) {
        nodes {
          group
          serviceName
          serviceId
        }
      }
    }
  `)

  const { nodes } = data.allContentfulService

  const serviceGroups = Array.from(new Set(nodes.map(node => node.group)))

  const serviceItems: MenuItemType[] = serviceGroups.map(group => {
    return {
      title: group,
      url: `/${group.toLowerCase()}/`, // not implemented, may be an empty string
      submenu: nodes
        .filter(node => node.group === group)
        .map(node => {
          return {
            title: node.serviceName,
            url: `/services/${node.serviceId}/`,
          }
        }),
    }
  })

  const staticItems: MenuItemType[] = [
    {
      title: 'Insights',
      url: '/insights/',
      submenu: [
        {
          title: 'Articles and Guides',
          url: '/blog/',
        },
        {
          title: 'Videos',
          url: '/video/',
        },
        {
          title: 'Podcast',
          url: '/podcast/',
        },
      ],
    },
  ]

  return serviceItems.concat(staticItems)
}
