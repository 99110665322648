import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Stage, useTransition } from 'transition-hook'

interface TransitionFadeProps {
  isVisible?: boolean
  children: ReactNode
  duration?: number
}

export function TransitionFade({ isVisible, children, duration = 200 }: TransitionFadeProps) {
  const { stage, shouldMount } = useTransition(isVisible, duration)

  return (
    shouldMount && (
      <ChildrenWrapper stage={stage} duration={duration / 1000}>
        {children}
      </ChildrenWrapper>
    )
  )
}

const ChildrenWrapper = styled.div<{ stage: Stage; duration: number }>`
  transition-timing-function: ease-in-out;
  transition-duration: ${({ duration }) => duration}s;
  opacity: ${({ stage }) => getOpacityFromTransition(stage)};
`

function getOpacityFromTransition(stage: Stage): number {
  if (stage === 'from') return 0
  if (stage === 'enter') return 1
  if (stage === 'leave') return 0

  return 0
}
