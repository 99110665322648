import { css } from '@linaria/core'

export const SearchBoxCommonStyles = css`
  display: flex;
  align-items: center;
  height: 36px;
  flex-grow: 1;
  padding: 0 8px;
  border-radius: 8px;
`
