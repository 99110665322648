import React from 'react'
import { useSnapshot } from 'valtio'
import { PostsGrid, Tags } from './components'
import { PageHeading, Envelope } from '../common'
import { tagState } from './state'

export const Blog = () => {
  const { selectedTag } = useSnapshot(tagState)

  return (
    <Envelope>
      <PageHeading title={selectedTag ?? 'All articles and guides'} />
      <Tags />
      <PostsGrid />
    </Envelope>
  )
}
