import { proxy } from 'valtio'

export const selectedVideoState = proxy<{ selectedId: string | null }>({ selectedId: null })

export const selectVideo = (videoId: string) => {
  selectedVideoState.selectedId = videoId
}

export const closePlayer = () => {
  selectedVideoState.selectedId = null
}
