import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { navState, toggleNav } from '../state'
import { desktopSmallMedia } from '@/lib/theme'
import { IconButtonCommon } from '@/UI'

export const MenuBurger = () => {
  const { isNavVisible } = useSnapshot(navState)

  return (
    <Button onButtonClick={toggleNav} gridArea="burger" title="Menu" aria-label="Toggle Menu">
      <Inner isRotated={isNavVisible}>
        <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </svg>
      </Inner>
    </Button>
  )
}

const Button = styled(IconButtonCommon)`
  margin-right: 16px;
  ${desktopSmallMedia} {
    display: none;
  }
`

const Inner = styled.div<{ isRotated: boolean }>`
  padding: 0;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  display: block;
  cursor: pointer;
  justify-self: end;
  align-self: center;
  transition-duration: 0.1s;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(90deg)' : 'none')};

  &:active {
    transform: scale(1.2);
  }
`
