import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { useGoToContacts } from '../hooks/useGoToContacts'
import { IconButtonCommon } from '@/UI'

export const ButtonContactMob = () => {
  const clickHandler = useGoToContacts()

  return (
    <Button onButtonClick={clickHandler}>
      <svg width="22" height="22" viewBox="0 0 16 16" fill="currentColor">
        <path d="m5.556 16c-.091 0-.182-.019-.269-.058-.242-.106-.398-.345-.398-.61v-3.058h-2.593c-.602 0-1.171-.218-1.602-.613-.441-.4-.694-.964-.694-1.543v-5.96c0-.579.253-1.143.694-1.546.432-.395 1.001-.612 1.602-.612h11.407c.603 0 1.172.217 1.603.612.44.403.694.966.694 1.545v5.96c0 .579-.254 1.142-.694 1.546-.43.394-.999.612-1.602.612h-3.816l-3.882 3.549c-.126.116-.287.176-.45.176zm-3.26-12.667c-.264 0-.52.096-.703.262-.168.154-.26.353-.26.562v5.96c0 .209.092.408.26.561.183.168.439.262.703.262h3.259c.368 0 .667.299.667.667v2.212l2.957-2.704c.123-.113.283-.176.45-.176h4.074c.265 0 .521-.095.702-.261.169-.154.26-.353.26-.562v-5.96c0-.209-.092-.408-.26-.562-.181-.166-.437-.261-.702-.261z" />
        <circle cx="4.411" cy="7.076" r="1.077" />
        <circle cx="8" cy="7.076" r="1.077" />
        <circle cx="11.59" cy="7.076" r="1.077" />
      </svg>
    </Button>
  )
}
const Button = styled(IconButtonCommon)`
  padding-bottom: 2px;
  margin-left: 16px;
  color: ${colors.primary.primary};
  
  ${desktopSmallMedia} {
    display: none;
  }
`
