import React from 'react'
import { styled } from '@linaria/react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { TextLink, TitleH2 } from '@/UI'
import { desktopSmallMedia } from '@/lib/theme'
import { RichTextImproved } from '@/blocks/rich-text-improoved'

export const FactoidsText = () => {
  const data = useStaticQuery(graphql`
    query WhoWeAreQuery {
      snippetWhoWeAre: contentfulSnippet(contentfulid: { eq: "whoweare-2024" }) {
        title
        content {
          raw
        }
        url
        linkLabel
      }
    }
  `)
  const { title, content, url, linkLabel } = data.snippetWhoWeAre

  return (
    <Root>
      <TitleH2>{title}</TitleH2>
      <TextStyled content={content} />
      <Link to={url}>
        <TextLink>{linkLabel}</TextLink>
      </Link>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 480px;

  ${desktopSmallMedia} {
    max-width: unset;
    justify-self: unset;
  }
`

const TextStyled = styled(RichTextImproved)`
  margin-bottom: 24px;
`
