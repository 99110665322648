
import { styled } from '@linaria/react'

const HighlightGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 133%;
  color: #9e958f;
  gap: 8px;
  align-items: center;
  align-self: end;

  span {
    font-weight: 100;
    font-size: 80px;
    line-height: 80px;
    padding-bottom: 8px;
  }
`

export default HighlightGrid