import React from 'react'
import { css } from '@linaria/core'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { useCloseSearchHandler } from '../../useCloseSearchHandler'

interface Props {
  className?: string
}

export const Logo = ({ className }: Props) => {
  const closeSearchHandler = useCloseSearchHandler()

  return (
    <Container className={className}>
      <Link to="/" onClick={closeSearchHandler}>
        <ImageHolder>
          <Image className={AnimationStyles} src="/SAMexpert-W.min.svg" alt="SAMexpert logo" />
        </ImageHolder>
      </Link>
    </Container>
  )
}

const ImageHolder = styled.div`
  width: 100%;
  height: 32px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Image = styled.img``

const AnimationStyles = css`
  transition-timing-function: ease-in-out;
  transition-duration: 3.6s, 0.4s;
  transition-delay: 0s, 0.2s;
  animation: transform-fade 0.6s ease-in-out, opacity-fade 0.8s ease-in-out;

  @keyframes transform-fade {
    from {
      transform: translateY(50%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes opacity-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 48px;
  width: 106px;
  padding-top: 3px;
  user-select: none;

  & > a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`
