import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia, //desktopLargeMedia
} from '@/lib/theme'

const StepText = styled.div`
  grid-area: ${props => 4 + 4 * props.index} / 4 / ${props => 5 + 4 * props.index} / 5;
  padding-bottom: 48px;
  :last-of-type {
    padding-bottom: 0;
  }
  ${desktopSmallMedia} {
    grid-area: -1 / ${props => 1 + 5 * props.index} / -2 / ${props => 5 + 5 * props.index};
    padding-bottom: 0;
  }
`

export default StepText
