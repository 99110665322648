import React from 'react'
import { styled } from '@linaria/react'
import { SearchIcon } from '../common'
import { SearchBoxCommonStyles } from './query'
import { desktopSmallMedia } from '@/lib/theme'
import { showSearch } from '../../state'

export const MenuSearchDesktop = () => {
  return (
    <Container className={SearchBoxCommonStyles} onClick={showSearch}>
      <SearchIcon size={20} />
      <Placeholder>Search</Placeholder>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: #bfbfbf;
  align-self: center;
  margin-right: 48px;
  display: none;
  cursor: pointer;
  user-select: none;
  width: 264px;
  flex-grow: 0;
  
  ${desktopSmallMedia} {
    display: flex;
  }
`

const Placeholder = styled.span`
  color: #9e958f;
  margin-left: 8px;
`
