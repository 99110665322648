import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia, //desktopLargeMedia
} from '@/lib/theme'

const StepSubTitle = styled.p`
  padding-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 133%;
  ${desktopSmallMedia} {
    text-align: center;
  }
`
export default StepSubTitle