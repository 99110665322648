import React from 'react'
import { styled } from '@linaria/react'
import { podcast } from '_/config/site-config'
import { FeedName, Feed } from '../types'
import { FeedItem } from './FeedItem'
import { desktopSmallMedia } from '@/lib/theme'

const feeds = podcast.feeds as Record<FeedName, Feed>

export const Feeds = () => {
  return (
    <Container>
      {Object.keys(feeds).map(item => (
        <FeedItem key={item} type={item as FeedName} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;

  ${desktopSmallMedia} {
    flex: 0 0 auto;
  }
`
