import { css } from '@linaria/core'
import { hoverMedia, shadowStyle, transition } from '@/lib/theme'

export const boxShadow = css`
  transition: ${transition};

  ${hoverMedia} {
    &:hover {
      cursor: pointer;
      box-shadow: ${shadowStyle};
    }
  }
`
