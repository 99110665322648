import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { Fade } from '../../UI/Fade'
import { AspectRatioContainer } from '../../UI/AspectRatioContainer'
import { selectedVideoState, closePlayer } from './selectedVideoState'

export const Player = () => {
  const { selectedId } = useSnapshot(selectedVideoState)

  if (!selectedId) return null

  const src = `https://www.youtube.com/embed/${selectedId}?modestbranding=1&cc_load_policy=1&rel=0&autoplay=1`

  return (
    <Fade onFadeClick={closePlayer}>
      <Container verticalRatio="56%">
        <iframe title="SAMexpert TV" width="1280" height="720" src={src} allowFullScreen />
      </Container>
    </Fade>
  )
}

const Container = styled(AspectRatioContainer)`
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 48px);
`
