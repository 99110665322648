import React from 'react'
import { styled } from '@linaria/react'
import { FactoidsText, Pieces } from './components'
import { Wrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'

export const Factoids = () => {
  return (
    <Root>
      <Wrapper $noMobilePadding={false}>
        <Inner>
          <FactoidsText />
          <Pieces />
        </Inner>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  background-color: ${colors.background.grayLightest};
  padding: 48px 0;
  
  ${desktopSmallMedia} {
    padding: 64px 0;
  }
`

const Inner = styled.div`
  display: grid;
  grid-row-gap: 32px;

  ${desktopSmallMedia} {
    grid-template-columns: 40% 50%;
    grid-column-gap: 10%;
  }
`
