import { styled } from '@linaria/react'
import {
  // tabletMedia,
  desktopSmallMedia, //desktopLargeMedia
} from '@/lib/theme'

const StepConnector = styled.div`
  grid-area: ${props => 3 + 4 * props.index} / 2 / ${props => 5 + 4 * props.index} / 3;
  border-top: none;
  border-left: 1px solid black;
  ${desktopSmallMedia} {
    grid-area: 2 / ${props => 4 + 5 * props.index} / 3 / ${props => 7 + 5 * props.index};
    border-top: 1px solid black;
    border-left: none;
  }
`

export default StepConnector
