import React from 'react'
import { Link } from 'gatsby'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { colors, hoverMedia, theme } from '@/lib/theme'
import { Dropdown } from './Dropdown'
import { MenuItemType } from '../../../types'
import { ExternalLink, TransitionFade } from '@/UI'
import { dropdownState, hideDropdown, showDropdown } from '@/blocks/layout/partials/header/state'

interface Props {
  item: MenuItemType
}

export const MenuItem = ({ item }: Props) => {
  const { submenu, url, title, external } = item
  const { visibleDropdown } = useSnapshot(dropdownState)
  const dropdownId = submenu && url
  const dropdownIsVisible = visibleDropdown === dropdownId

  const onMouseEnter = () => {
    if (dropdownId) showDropdown(dropdownId)
  }

  const onMouseLeave = () => {
    if (dropdownId) hideDropdown()
  }

  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {submenu && (
        <>
          <Button aria-haspopup="menu" aria-expanded={dropdownIsVisible} $isActive={dropdownIsVisible}>
            {title}
          </Button>
          <TransitionFade isVisible={dropdownIsVisible} duration={100}>
            <Dropdown submenus={item.submenu} />
          </TransitionFade>
        </>
      )}
      {!submenu && external && (
        <a className={FontStyle} href={url} target="_blank" rel="noopener noreferrer">
          {item.title}
          {item.external && <ExternalLink size={20} />}
        </a>
      )}
      {!submenu && !external && (
        <Link className={FontStyle} to={url} target="_self">
          {item.title}
        </Link>
      )}
    </Container>
  )
}
const FontStyle = css`
  color: ${colors.tertiary.tertiary};
  display: flex;
  align-items: center;
  height: 100%;

  ${hoverMedia} {
    &:hover {
      color: ${theme.hover};
    }
  }
`

const Container = styled.li`
  height: 100%;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
`
const Button = styled.button<{ $isActive: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: inherit;
  position: relative;
  color: ${colors.white};
  
  &:after {
    content: '';
    display: block;
    position:absolute;
    pointer-events: none;
    width: 100%;
    height: 2px;
    transition: background-color 0.3s ease-in-out;
    background-color:${({ $isActive }) => ($isActive ? colors.primary.primary : 'transparent')};
    bottom: calc(50% - 14px);
  }
`
