import React, { ReactNode } from 'react'
import { FeedName } from '../types'

export const icons = {
  apple: () => (
    <>
      <linearGradient
        id="a"
        gradientTransform="matrix(-1 0 0 -1 24 24)"
        gradientUnits="userSpaceOnUse"
        x1="12"
        x2="12"
        y1="24"
        y2="0"
      >
        <stop offset="0" stopColor="#f452ff" />
        <stop offset="1" stopColor="#832bc1" />
      </linearGradient>
      <path
        clipRule="evenodd"
        d="m0 5c0-2.75 2.25-5 5-5h14c2.75 0 5 2.25 5 5v14c0 2.75-2.25 5-5 5h-14c-2.75 0-5-2.25-5-5z"
        fill="url(#a)"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path d="m14.056 14.488c-.032-.288-.128-.496-.32-.688-.36-.376-.992-.624-1.736-.624s-1.376.24-1.736.624c-.184.2-.288.4-.32.688-.064.56-.024 1.04.04 1.816.064.736.184 1.72.336 2.712.112.712.2 1.096.28 1.368.136.448.624.832 1.4.832s1.272-.392 1.4-.832c.08-.272.168-.656.28-1.368.152-1 .272-1.976.336-2.712.072-.776.104-1.256.04-1.816z" />
        <path d="m13.968 10.408c0 1.088-.88 1.968-1.968 1.968s-1.968-.88-1.968-1.968.88-1.968 1.968-1.968 1.968.888 1.968 1.968z" />
        <path d="m11.976 2.656c-4.592.016-8.352 3.744-8.416 8.336-.048 3.72 2.328 6.904 5.648 8.072.08.032.16-.04.152-.12-.04-.288-.088-.576-.12-.864-.016-.104-.08-.184-.168-.232-2.624-1.144-4.456-3.776-4.424-6.824.04-4 3.304-7.256 7.296-7.288 4.088-.032 7.424 3.28 7.424 7.36 0 3.016-1.824 5.608-4.424 6.752-.096.04-.16.128-.168.232-.04.288-.08.576-.12.864-.016.088.072.152.152.12 3.288-1.152 5.648-4.288 5.648-7.968-.016-4.656-3.816-8.448-8.48-8.44z" />
        <path d="m11.784 5.456c-2.992.112-5.392 2.584-5.432 5.576-.024 1.968.96 3.712 2.472 4.744.072.048.176-.008.176-.096-.024-.344-.024-.648-.008-.968.008-.104-.032-.2-.112-.272-.92-.864-1.48-2.096-1.448-3.456.064-2.4 1.992-4.352 4.392-4.448 2.608-.104 4.752 1.992 4.752 4.568 0 1.312-.56 2.496-1.448 3.336-.072.072-.112.168-.112.272.016.312.008.616-.008.96-.008.088.096.152.176.096 1.488-1.016 2.472-2.736 2.472-4.672.008-3.184-2.656-5.768-5.872-5.64z" />
      </g>
    </>
  ),
  youtube: () => (
    <>
      <path d="m11.987 20.801s7.518 0 9.383-.504c1.05-.286 1.84-1.109 2.117-2.125.513-1.865.513-5.788.513-5.788s0-3.898-.512-5.746c-.277-1.042-1.067-1.848-2.117-2.125-1.865-.513-9.384-.513-9.384-.513s-7.501 0-9.358.512c-1.033.278-1.839 1.084-2.133 2.126-.496 1.848-.496 5.746-.496 5.746s0 3.923.496 5.788c.294 1.017 1.1 1.84 2.134 2.125 1.856.504 9.357.504 9.357.504z" fill="#f00"/>
      <path d="m15.827 12.384-6.225-3.587v7.199z" fill="#fff"/>
    </>
  ),
  spotify: () => (
    <>
      <path d="m12 0c-6.624 0-12 5.376-12 12s5.376 12 12 12 12-5.376 12-12-5.376-12-12-12z" fill="#1ed760"/>
      <path d="m19.674 10.795c-.252 0-.406-.063-.624-.189-3.445-2.056-9.605-2.55-13.592-1.437-.174.049-.392.126-.624.126-.639 0-1.127-.498-1.127-1.142 0-.658.406-1.031.842-1.156 1.703-.498 3.61-.735 5.685-.735 3.532 0 7.234.735 9.939 2.313.377.218.624.518.624 1.094 0 .657-.532 1.126-1.123 1.126zm-1.5 3.687c-.252 0-.421-.111-.595-.203-3.024-1.79-7.534-2.511-11.545-1.423-.232.063-.358.126-.576.126-.518 0-.939-.421-.939-.939s.252-.861.75-1.002c1.345-.377 2.719-.658 4.732-.658 3.14 0 6.174.779 8.565 2.202.392.232.547.532.547.953-.005.523-.411.944-.939.944zm-1.301 3.174c-.203 0-.329-.063-.518-.174-3.019-1.819-6.532-1.897-10.002-1.185-.189.048-.435.126-.576.126-.469 0-.765-.373-.765-.765 0-.498.295-.735.658-.813 3.963-.876 8.013-.798 11.468 1.268.295.189.469.358.469.798s-.342.745-.734.745z" fill="#fff"/>
  </>
  ),
  rss: () => (
    <>
      <path d="m4.5 0h15c2.485 0 4.5 2.015 4.5 4.5v15c0 2.485-2.015 4.5-4.5 4.5h-15c-2.485 0-4.5-2.015-4.5-4.5v-15c0-2.485 2.015-4.5 4.5-4.5z"
            fill="#0072ef"/>
      <g fill="#fff">
        <circle cx="6" cy="18" r="3"/>
        <path d="m3 12c4.971 0 9 4.029 9 9h3c0-6.627-5.373-12-12-12z"/>
        <path d="m3 6c8.284 0 15 6.716 15 15h3c0-9.941-8.059-18-18-18z"/>
      </g>
    </>
  )
}
