import React from 'react'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import { boxShadow } from '@/UI'

interface Props {
  text?: string
  onButtonClick: () => void
}

export const ShowMoreBtn = ({ onButtonClick, text = 'Load More' }: Props) => {
  return (
    <Container className={boxShadow} onClick={onButtonClick}>
      {text}
    </Container>
  )
}

const Container = styled.button`
  height: 36px;
  padding: 0 16px;
  background-color: ${colors.primary.primary};
  color: #fff;
  border-radius: 4px;
  justify-self: center;
  cursor: pointer;
`
