import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { desktopSmallMedia } from '@/lib/theme'
import { slugify } from '@/utils/slugify'
import { Episode } from '../types'
import { BlockCover, BlockDate, BlockPlayer, TitleSecond } from './common'

interface Props {
  data: Episode
}

export const RegularEpisode = ({ data }: Props) => {
  const { bytes, file, pubDate, id, shortDescription, image, title, podcast } = data
  const { cdnFolder } = podcast

  return (
    <Link to={`/podcast/${slugify(title)}`}>
      <Container>
        <MainBlock>
          <Texts>
            <BlockDate inputDate={pubDate} />
            <TitleSecond text={title} isOnMainPage />
            <Description dangerouslySetInnerHTML={{ __html: shortDescription }} />
          </Texts>
          <BlockPlayer file={file} id={id} bytes={bytes} title={title} cdnFolder={cdnFolder} />
        </MainBlock>
        <BlockCover image={image} />
      </Container>
    </Link>
  )
}

const MainBlock = styled.div`
  display: grid;
  grid-row-gap: 24px;

  ${desktopSmallMedia} {
    max-width: 812px;
    flex-shrink: 1;
    margin-right: 48px;
  }
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.63;
  opacity: 0.5;
`

const Texts = styled.div`
  display: grid;
  grid-row-gap: 16px;
  width: 100%;
`

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  border-radius: 16px;
  padding: 24px 24px 32px 24px;
  background: rgba(219, 216, 212, 0.54);

  ${desktopSmallMedia} {
    padding: 48px;
  }
`
