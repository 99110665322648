import React from 'react'
import { styled } from '@linaria/react'
import { FieldAppearanceStatus } from '../types'

interface Props {
  size?: number
  className?: string
  status: FieldAppearanceStatus
}

export const InputStatusIcon = ({ size = 16, status, className }: Props) => {
  if (status === 'initial') return null

  return (
    <Container $size={size} className={className}>
      <svg width={size} height={size} viewBox="0 0 16 16" fill="currentColor">
        {status === 'isOk' ? (
          <path d="m15 4.25-1.333-1.25-8.002 7.498-3.332-3.123-1.333 1.25 4.667 4.375z" />
        ) : (
          <>
            <path d="m7.994 2c-3.312 0-5.994 2.688-5.994 6s2.682 6 5.994 6c3.318 0 6.006-2.688 6.006-6s-2.688-6-6.006-6zm.006 10.8c-2.652 0-4.8-2.148-4.8-4.8s2.148-4.8 4.8-4.8 4.8 2.148 4.8 4.8-2.148 4.8-4.8 4.8z"/>
            <path d="m7.4 5h1.2v3.6h-1.2z"/>
            <path d="m7.4 9.8h1.2v1.2h-1.2z"/>
          </>
        )}
      </svg>
    </Container>
  )
}

const Container = styled.div<{ $size: number }>`
  display: flex;
  width: 16px;
  height: 16px;
`
