import React from 'react'
import { styled } from '@linaria/react'
import { ExternalLink } from '@/UI'
import { SingleLinkContent } from './AllLinksWrapper'

interface Props {
  href: string
  text: string
}

export const ExternalLinkTemplate = ({ text, href }: Props) => {
  return (
    <Container href={href} target="_blank" rel="noreferrer">
      <SingleLinkContent>
        {text}
        <ExternalLink />
      </SingleLinkContent>
    </Container>
  )
}

const Container = styled.a`
  display: flex;
`
