import { styled } from '@linaria/react'

const SubSteps = styled.ul`
  padding-left: 16px;
  list-style: disc;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
`

export default SubSteps