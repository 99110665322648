import { styled } from '@linaria/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { fontLeagueSpartan } from '@/lib/theme'

export const HighlightGatsbyImage = styled(GatsbyImage)`
  mix-blend-mode: lighten;
`

export const highlightTitleStyles = {
  fontFamily: fontLeagueSpartan,
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.1,
  letterSpacing: '-0.01em',
}