import React, { useMemo } from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { colors, hoverMedia, tabletMedia } from '@/lib/theme'
import { fieldHandlersFabric, customFormParams } from '../formState'
import { FieldData, FieldAppearanceStatus, FieldId, InputUtilityData } from '../types'
import { InputStatusIcon } from './InputStatusIcon'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'

const inputAppearance: Record<FieldAppearanceStatus, { background: string; color: string }> = {
  initial: { background: colors.white, color: 'transparent' },
  isOk: { background: colors.outcome.positive.lightest, color: colors.outcome.positive.positive },
  isError: { background: colors.outcome.negative.lightest, color: colors.outcome.negative.negative },
}

const inputUtilityDataDefault: Record<FieldId, InputUtilityData> = {
  name: {
    label: 'Full Name',
    errorText: 'Please enter your full name',
  },
  email: {
    label: 'Email',
    errorText: 'Please enter a valid email',
  },
  message: {
    label: 'Please tell us about your challenges',
    errorText: 'Please provide details so we could better help you',
  },
}

interface Props {
  fieldData: FieldData
  appearance: AppearanceType
}

export const Input = ({ fieldData, appearance }: Props) => {
  const { id, type, value, appearanceStatus, inputState, autoComplete } = useSnapshot(fieldData, { sync: true })
  const { messageHint } = useSnapshot(customFormParams)
  const { setFieldValue, onBlur, onFocus } = useMemo(() => {
    return fieldHandlersFabric(fieldData)
  }, [fieldData])

  const { label } = inputUtilityDataDefault[id]
  const labelFinal = messageHint && id === 'message' ? messageHint : label

  const isError = appearanceStatus === 'isError'
  const isSmall = Boolean(value) || inputState === 'isOnFocus'

  return (
    <Container $status={appearanceStatus} $id={id} $hasBorder={appearance === 'light'}>
      {labelFinal && <TextLabel $isSmall={isSmall}>{labelFinal}</TextLabel>}
      <InputView
        as={type ? 'input' : 'textarea'}
        onChange={setFieldValue}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        name={id}
        type={type}
        autoComplete={autoComplete}
        $id={id}
      />
      <FloatingStatus $status={appearanceStatus}>
        <InputStatusIcon status={appearanceStatus} />
        {isError && <StatusText>{inputUtilityDataDefault[type].errorText}</StatusText>}
      </FloatingStatus>
    </Container>
  )
}

const Container = styled.label<{ $status: FieldAppearanceStatus; $id: FieldId; $hasBorder: boolean }>`
  cursor: text;
  display: flex;
  border-radius: 8px;
  position: relative;
  max-width: calc(100vw - 48px);
  align-items: flex-start;
  transition-duration: 0.2s;
  padding: 28px 16px 12px 16px;
  transition-timing-function: ease-in-out;
  transition-property: color, background-color, box-shadow;
  height: ${({ $id }) => ($id !== 'message' ? 64 : 200)}px;
  background-color: ${({ $status }) => inputAppearance[$status].background};
  box-shadow: 0 0 3px 3px ${({ $status }) => getBorderColor($status, false, false)} inset;
  border-width: 1px;
  border-color: ${colors.secondary.secondary};
  border-style: ${({ $hasBorder }) => ($hasBorder ? 'solid' : 'none')};

  ${tabletMedia} {
    max-width: ${({ $id }) => ($id !== 'message' ? '480px' : '100%')};
  }

  ${hoverMedia} {
    &:hover {
      box-shadow: 0 0 3px 3px ${({ $status }) => getBorderColor($status, false, true)} inset;
    }
  }

  &:focus-within {
    box-shadow: 0 0 3px 3px ${({ $status }) => getBorderColor($status, true, false)} inset;
  }
`

const TextLabel = styled.span<{ $isSmall: boolean }>`
  top: 22px;
  left: 16px;
  color: #9e958f;
  line-height: 1;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  letter-spacing: -0.4px;
  transform-origin: left;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: transform, width;
  transform: ${({ $isSmall }) => ($isSmall ? 'scale(60%) translateY(-120%)' : 'unset')};
  width: ${({ $isSmall }) => ($isSmall ? 'calc(100% + 128px)' : 'calc(100% - 32px)')};
`

const InputView = styled.input<{$id: FieldId;}>`
  flex-grow: 1;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: ${({ $id }) => ($id !== 'message' ? 1 : 1.35)};
  letter-spacing: -0.4px;
  height: 100%;
  border: none;
  background-color: transparent !important;
  resize: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`

const FloatingStatus = styled.div<{ $status: FieldAppearanceStatus }>`
  font-size: 14px;
  padding-left: 2px;
  height: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.25px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, color;
  opacity: ${({ $status }) => ($status === 'initial' ? 0 : 1)};
  color: ${({ $status }) => inputAppearance[$status].color};
  position: absolute;
  left: 0;
  bottom: -18px;
  display: flex;
  align-items: center;
  pointer-events: none;
`

const StatusText = styled.span`
  margin-left: 4px;
`

function getBorderColor(status: FieldAppearanceStatus, isOnFocus: boolean, isOnHover: boolean) {
  const doNotChangeColor = status === 'isOk' || status === 'isError'

  switch (true) {
    case isOnFocus && !doNotChangeColor:
      return '#66d1ff'
    case isOnHover && !doNotChangeColor:
      return colors.primary.primary
    default:
      return inputAppearance[status].color
  }
}
