import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopLargeMedia, desktopMediumMedia, desktopSmallMedia } from '@/lib/theme'
import { Wrapper } from '@/UI'
import type { Post } from './types'
import { ContactForm } from '@/blocks/contact-form'
import { TitleBlock, Authors, Content, Info, TocDesktop, OutputControls, RelatedLinks } from './components'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import type { RichTextContentType } from '@/blocks/rich-text-improoved'

interface Props {
  post: Post
  slug: string
}

export const Article = ({ post, slug }: Props) => {
  const { tags, title, authors, datetime, content, excerpt } = post

  return (
    <Root>
      <TitleBlock title={title} tags={tags} />
      <TopInteractionsBlock title={title} slug={slug} />
      <Info authors={authors} datetime={datetime} />
      <Content content={content as RichTextContentType} summary={excerpt?.excerpt} />
      <TocDesktop content={content} />
      <Form appearance="light" />
      <BottomInteractionsBlock title={title} slug={slug} />
      <Authors authors={authors} />
      <RelatedLinks tags={tags} />
    </Root>
  )
}

const Root = styled(Wrapper)`
  display: grid;
  grid-column-gap: 24px;
  margin-bottom: 64px;
  grid-template-areas:
    'title'
    'info'
    'content'
    'form'
    'author-ext'
    'related';

  ${desktopSmallMedia} {
    grid-template-columns: auto 256px;
    grid-template-areas:
      'title title'
      'info interactions-top'
      'content toc'
      'form .'
      'interactions-bottom .'
      'author-ext .'
      'related .';
  }

  ${desktopMediumMedia} {
    grid-template-columns: auto 306px;
    grid-template-areas:
      'title interactions-top'
      'info info'
      'content toc'
      'form .'
      'interactions-bottom .'
      'author-ext .'
      'related .';
  }

  ${desktopLargeMedia} {
    grid-template-columns: 196px auto 306px;
    grid-template-areas:
      'title title interactions-top'
      'info content toc'
      '. form .'
      '. interactions-bottom .'
      'author-ext author-ext .'
      '. related .';
  }
`

const TopInteractionsBlock = styled(OutputControls)`
  grid-area: interactions-top;
  display: none;
  justify-self: end;
  ${doNotPrintThisBlock};

  ${desktopSmallMedia} {
    display: flex;
    padding-bottom: 24px;
  }

  ${desktopMediumMedia} {
    align-items: end;
    padding-bottom: 68px;
  }
`

const BottomInteractionsBlock = styled(OutputControls)`
  grid-area: interactions-bottom;
  display: none;
  padding-top: var(--content-blocks-gap);
  margin-top: var(--content-blocks-gap);
  position: relative;
  ${doNotPrintThisBlock};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: ${colors.tertiary.tertiary};
  }

  ${desktopSmallMedia} {
    display: flex;
  }
`

const Form = styled(ContactForm)`
  grid-area: form;
  margin-top: 48px;
`
