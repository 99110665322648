import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { Styleable } from '@/lib/interfaces'

interface Props extends Styleable {
  text: string
  isOnMainPage?: boolean
}

export const TitleMainOld = ({ text, isOnMainPage, className }: Props) => {
  return (
    <TitleMainView
      className={className}
      as={isOnMainPage ? 'h2' : 'h1'}
      dangerouslySetInnerHTML={{ __html: text }}
      isOnMainPage={isOnMainPage}
    />
  )
}

const TitleMainView = styled.h1<{ isOnMainPage: boolean }>`
  color: ${({ isOnMainPage }) => (isOnMainPage ? colors.textStyles.inverse : colors.textStyles.regular)};
  font-size: 36px;
  font-weight: 600;
  line-height: 1.05;
  letter-spacing: -0.01em;

  ${desktopSmallMedia} {
    font-size: 60px;
  }
`
