import { styled } from '@linaria/react'
import { desktopSmallMedia } from '@/lib/theme'

type Direction = 'top' | 'bottom' | 'left' | 'right'

export interface BreakProps {
  $direction?: Direction
  $indent: number
  $indentMobile?: number
}

export const Break = styled.div<BreakProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: ${({ $direction, $indentMobile }) => getMargin($indentMobile, $direction)};

  ${desktopSmallMedia} {
    margin: ${({ $direction, $indent }) => getMargin($indent, $direction)};
  }
`

function getMargin($indent: number, $direction: Direction = 'bottom') {
  if (!$indent) return '0'

  switch ($direction) {
    case 'bottom':
      return `0 0 ${$indent}px 0`
    case 'top':
      return `${$indent}px 0 0 0`
    case 'right':
      return `0 ${$indent}px 0 0`
    case 'left':
      return `0 0 0 ${$indent}px`
    default:
      return '0'
  }
}
