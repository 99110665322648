function reWrap(input) {
  const contentToProcess = input?.raw
  let output = {}

  try {
    if (!contentToProcess) throw new Error('non content')
    output = JSON.parse(contentToProcess).content
  } catch (e) {
    return {
      nodeType: 'document',
      content: {},
    }
  }

  return {
    nodeType: 'document',
    content: output,
  }
}

module.exports = { reWrap }
