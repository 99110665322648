import React from 'react'
import { Link } from 'gatsby'
import { AllLinksWrapper, SingleLinkContent } from './AllLinksWrapper'

export const Insights = () => {
  return (
    <AllLinksWrapper gridArea="insights">
      <h2>Insights</h2>
      <Link to="/blog/">
        <SingleLinkContent>Articles</SingleLinkContent>
      </Link>
      <Link to="/video/">
        <SingleLinkContent>Video</SingleLinkContent>
      </Link>
      <Link to="/podcast/">
        <SingleLinkContent>Podcast</SingleLinkContent>
      </Link>
    </AllLinksWrapper>
  )
}
