import { styled } from '@linaria/react'
import { media } from '@/lib/theme'

export const AnnouncesGrid = styled.div<{ $handlesTabletMq?: boolean }>`
  display: grid;
  grid-gap: 16px;
  align-self: center;

  ${media.tablet} {
    grid-template-columns: ${({ $handlesTabletMq }) => ($handlesTabletMq ? 'repeat(2, 1fr)' : 'unset')};
  }

  ${media.desktopSmall} {
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    align-self: unset;
  }
`
