import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { styled } from '@linaria/react'

const interactiveElementSelector = 'a, button' as const

interface RippleProps {
  duration?: string
  fillColor?: string
}

export const Ripple: FunctionComponent<RippleProps> = ({ fillColor, duration = '0.6s' }) => {
  const [ripple, setRipple] = useState<null | {
    id: number
    size: number
    x: number
    y: number
  }>(null)
  const ref = useRef<HTMLSpanElement>(null)

  const handlePointerDown = useCallback((event: PointerEvent) => {
    const rippleElement = ref.current
    if (!rippleElement) return
    const parent = rippleElement.parentElement?.closest(interactiveElementSelector)
    if (!parent) return
    if (!(event.target instanceof HTMLElement) && !(event.target instanceof SVGElement)) return
    const interactedWithElement = event.target.closest(interactiveElementSelector)
    if (interactedWithElement !== parent) return
    if (interactedWithElement instanceof HTMLButtonElement && interactedWithElement.disabled) return

    const rect = parent.getBoundingClientRect()
    const size = 2 * Math.sqrt(rect.width ** 2 + rect.height ** 2)
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    setRipple({
      id: event.timeStamp,
      size,
      x,
      y,
    })
  }, [])

  useEffect(() => {
    document.body.addEventListener('pointerdown', handlePointerDown)
    return () => {
      document.body.removeEventListener('pointerdown', handlePointerDown)
    }
  }, [handlePointerDown])

  const discardRipple = useCallback(() => {
    setRipple(null)
  }, [])

  return (
    <Wrapper ref={ref}>
      {ripple && (
        <RippleImpl
          key={ripple.id}
          $coordX={ripple.x}
          $coordY={ripple.y}
          $size={ripple.size}
          $duration={duration}
          $fillColor={fillColor}
          onAnimationEnd={discardRipple}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.span`
  display: contents;
`

interface ViewProps {
  $duration: string
  $size: number
  $coordX: number
  $coordY: number
  $fillColor: string | undefined
}

const RippleImpl = styled.span<ViewProps>`
  @media (prefers-reduced-motion: no-preference) {
    --Ripple-opacity: 0.6;
    --Ripple-size: ${({ $size }) => $size}px;
    --Ripple-x: ${({ $coordX }) => $coordX}px;
    --Ripple-y: ${({ $coordY }) => $coordY}px;
    position: absolute;
    z-index: -1;
    top: calc(-0.5 * var(--Ripple-size) + var(--Ripple-y));
    left: calc(-0.5 * var(--Ripple-size) + var(--Ripple-x));
    width: var(--Ripple-size);
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: ${({ $fillColor }) => $fillColor ?? 'currentColor'};
    animation: ripple ${({ $duration }) => $duration} forwards ease-in;
    pointer-events: none;
  }

  @keyframes ripple {
    0% {
      transform: scale(0);
      opacity: var(--Ripple-opacity);
    }
    30% {
      opacity: var(--Ripple-opacity);
    }
    70% {
      transform: none;
    }
    100% {
      opacity: 0;
    }
  }
`
