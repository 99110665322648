import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Button, TitleH2, Typography } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { useBookACall } from '@/hooks'
import { RichTextContentType, RichTextImproved } from '@/blocks/rich-text-improoved'

interface Props {
  isNoBgOnMobile?: boolean
  content?: RichTextContentType
}

export const SideBlock = ({ isNoBgOnMobile, content }: Props) => {
  const clickHandler = useBookACall()

  const contentForeign = content ? <RichTextImproved content={content} /> : null

  const contentDefault = (
    <>
      <TitleH2 $gap={0} $gapMob={0}>
        Would you like a call instead?
      </TitleH2>
      <Typography>
        Ready to discuss your Microsoft licensing needs? Book a call with us today! Our experts will provide
        personalized guidance to help you navigate Microsoft’s licensing options, ensuring compliance and optimizing
        your software investments.{' '}
      </Typography>
    </>
  )

  return (
    <Root $isNoBoOnMob={isNoBgOnMobile}>
      {contentForeign ?? contentDefault}
      <Button type="secondary" onClick={clickHandler} desktopWidth="286px">
        Book a call
      </Button>
    </Root>
  )
}

const Root = styled.div<{ $isNoBoOnMob: boolean }>`
  display: grid;
  grid-row-gap: 32px;
  align-self: end;
  background-color: ${({ $isNoBoOnMob }) => ($isNoBoOnMob ? 'transparency' : colors.primary.lightest)};
  padding: ${({ $isNoBoOnMob }) => ($isNoBoOnMob ? '40px 24px 0 24px' : '40px 24px 40px 24px')};
  margin-bottom: 32px;

  ${desktopSmallMedia} {
    margin-bottom: 48px;
    background-color: transparent;
    padding: unset;
  }
`
