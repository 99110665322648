import React from 'react'
import { styled } from '@linaria/react'
import { LinkedIn, Twitter, Telegram, Youtube, Mail, Facebook } from './Icons'
import { Styleable } from '@/lib/interfaces'
import { baseUrls } from './baseUrls'
import { theme, media } from '@/lib/theme'

const icons = {
  LinkedIn,
  Twitter,
  Telegram,
  Youtube,
  Mail,
  Facebook,
}

export type IconsSocialType = keyof typeof icons
type InteractiveOnType = 'content' | 'background'

interface Props extends Styleable {
  type: IconsSocialType
  urlAdd?: string
  size?: number
  fillBg?: string
  fillContent?: string
  isNotInteractive?: boolean
  interactiveOn?: InteractiveOnType
}

export const IconSocial = ({
  type,
  className,
  urlAdd,
  size = 48,
  fillBg,
  fillContent,
  isNotInteractive,
  interactiveOn = 'background',
}: Props) => {
  const urlFinal = `${baseUrls[type]}${urlAdd ?? ''}`
  const Icon = icons[type]

  return (
    <IconContainer
      $size={size}
      className={className}
      as={urlAdd ? 'a' : 'div'}
      href={urlAdd ? urlFinal : null}
      target={urlAdd ? '_blank' : null}
      rel={urlAdd ? 'noreferer' : null}
      $fillBg={fillBg ?? 'unset'}
      $fillContent={fillContent ?? 'unset'}
      $interactiveOn={interactiveOn}
      $isNotInteractive={isNotInteractive}
    >
      <svg fill="none" height={size} width={size} viewBox="0 0 48 48">
        <Icon />
      </svg>
    </IconContainer>
  )
}

interface FillSVGProps {
  $fillBg?: string | undefined
  $fillContent?: string | undefined
  $isNotInteractive: boolean
  $interactiveOn: InteractiveOnType
}

const IconContainer = styled.a<
  FillSVGProps & {
    $size: number
  }
>`
  overflow: hidden;
  --svg-fill-bg: ${({ $fillBg }) => $fillBg};
  --svg-fill-content: ${({ $fillContent }) => $fillContent};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 6}px;

  & svg rect,
  svg path {
    transition: fill 0.2s ease-in-out;
  }

  ${media.hover} {
    &:hover {
      --svg-fill-bg: ${({ $fillBg, $fillContent, $isNotInteractive, $interactiveOn }) =>
        getInteractiveFill({
          $fillBg,
          $fillContent,
          $isNotInteractive,
          $interactiveOn,
          interactiveType: 'hover',
          elementType: 'background',
        })};
      --svg-fill-content: ${({ $fillBg, $fillContent, $isNotInteractive, $interactiveOn }) =>
        getInteractiveFill({
          $fillBg,
          $fillContent,
          $isNotInteractive,
          $interactiveOn,
          interactiveType: 'hover',
          elementType: 'content',
        })};
    }
  }
  &:active {
    --svg-fill-bg: ${({ $fillBg, $fillContent, $isNotInteractive, $interactiveOn }) =>
      getInteractiveFill({
        $fillBg,
        $fillContent,
        $isNotInteractive,
        $interactiveOn,
        interactiveType: 'active',
        elementType: 'background',
      })};
    --svg-fill-content: ${({ $fillBg, $fillContent, $isNotInteractive, $interactiveOn }) =>
      getInteractiveFill({
        $fillBg,
        $fillContent,
        $isNotInteractive,
        $interactiveOn,
        interactiveType: 'active',
        elementType: 'content',
      })};
  }
`

function getInteractiveFill({
  $fillBg,
  $fillContent,
  $isNotInteractive,
  $interactiveOn,
  interactiveType,
  elementType,
}: FillSVGProps & { interactiveType: 'hover' | 'active'; elementType: InteractiveOnType }) {
  const elementColorStd = elementType === 'background' ? $fillBg : $fillContent
  const elementColorInteractive = interactiveType === 'hover' ? theme.hover : theme.active

  if ($isNotInteractive) return elementColorStd
  if ($interactiveOn === elementType) return elementColorInteractive
  return elementColorStd
}
