import React from 'react'
import { styled } from '@linaria/react'
import { desktopSmallMedia, mobileModernMedia } from '@/lib/theme'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${mobileModernMedia} {
    margin-bottom: 48px;
  }

  ${desktopSmallMedia} {
    margin-bottom: 72px;
  }
`
