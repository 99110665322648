import { useCallback } from 'react'
import { hideSearch, resetSearchQuery, resetHitsCount, hideNav } from '../state'

export function useCloseSearchHandler(callback?: () => void) {
  return useCallback(() => {
    resetSearchQuery()
    hideSearch()
    hideNav()
    resetHitsCount()
    if(callback) callback()
  }, [callback])
}
