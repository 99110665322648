import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface Props {
  timeToSkip: number
  isBehind?: boolean
  onButtonClick: () => void
}

export const SkipBlock = ({ timeToSkip, isBehind, onButtonClick }: Props) => {
  return (
    <Container onClick={onButtonClick}>
      <IconWrapper isBehind={isBehind}>
        <svg width="48" height="48" viewBox="0 0 32 32" fill="currentColor">
          <path d="m16 29.009c-1.613 0-3.124-.307-4.533-.92s-2.635-1.441-3.678-2.483c-1.043-1.044-1.87-2.269-2.483-3.678-.613-1.408-.919-2.919-.92-4.533 0-1.613.307-3.124.92-4.533s1.441-2.635 2.483-3.678c1.043-1.043 2.269-1.87 3.678-2.483 1.408-.613 3.147-.93 4.761-.931l-2.034-1.989 1.806-1.87 5.162 5.162-5.162 5.161-1.807-1.871 2.047-2.006c-2.516 0-4.892.882-6.645 2.636-1.753 1.753-2.629 3.888-2.629 6.403 0 2.516.877 4.651 2.63 6.404s3.888 2.629 6.403 2.629c2.516 0 4.651-.877 6.404-2.63s2.629-3.888 2.629-6.403h2.581c0 1.613-.307 3.124-.92 4.533s-1.441 2.635-2.483 3.678c-1.043 1.043-2.269 1.871-3.678 2.484-1.407.613-2.918.919-4.532.918z" />
        </svg>
      </IconWrapper>
      <Text>{timeToSkip}</Text>
    </Container>
  )
}

const Container = styled.button`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  color: ${colors.tertiary.tertiary};
  transition: color 0.2s ease-in-out;
  position: relative;
  
  ${desktopSmallMedia} {
    display:none;
  }
  
  &:active {
    color: ${colors.primary.primary};
  }
`

const IconWrapper = styled.div<{ isBehind: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform: ${({isBehind}) => isBehind ? 'scaleX(-1)' : 'none'};
`

const Text = styled.span`
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
`
