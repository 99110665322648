import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { SEO, Layout } from '@/blocks/layout'
import { schemaOrgOrganization } from '@/utils/schema.org'
import { Home } from '~/features/home-page'

const LandingPage = () => {
  return (
    <Layout hasNoVerticalMargins>
      <Home />
    </Layout>
  )
}

export default LandingPage

export const Head = ({ data }: PageProps<Queries.LandingPageQueryQuery>) => {
  const site = data.site.siteMetadata
  const pageTitle = site.title
  const pageDescription = site.description
  const seoImage = getImage(data.seoImage.image.fixed).images.fallback.src

  return (
    <SEO title={pageTitle} description={pageDescription} image={seoImage} richresult={schemaOrgOrganization(site)} />
  )
}

export const pageQuery = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
    seoImage: contentfulDefaultImage(for: { eq: "SEO" }) {
      image {
        fixed: gatsbyImageData(width: 1200, height: 630, formats: [AUTO, WEBP], quality: 10)
      }
    }
  }
`
