import React from 'react'
import { styled } from '@linaria/react'
import { podcast } from '_/config/site-config'
import { desktopSmallMedia, hoverMedia } from '@/lib/theme'
import { icons } from './icons'
import type { FeedName, Feed } from '../types'

const feeds = podcast.feeds as Record<FeedName, Feed>

interface Props {
  type: FeedName
}

export const FeedItem = ({ type }: Props) => {
  const Icon = icons[type]
  const { url, name } = feeds[type]

  return (
    <Container href={url} target="_blank" download={name.toLowerCase().includes('rss')}>
      <IconContainer>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <Icon />
        </svg>
      </IconContainer>
      <Label>{name}</Label>
    </Container>
  )
}

const Container = styled.a`
  display: grid;
  grid-gap: 8px;

  ${desktopSmallMedia} {
    flex-grow: 1;
    height: 48px;
    align-items: center;
    grid-template-columns: 24px auto;

    &:not(:last-child) {
      margin-right: 48px;
    }
  }

  ${hoverMedia} {
    transition: 0.2s ease-in-out;
    transition-property: opacity, filter;
    &:hover {
      filter: grayscale(1);
      opacity: 0.6;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-self: center;
  justify-self: center;
`

const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.1;
`
