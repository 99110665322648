import { siteUrl } from '_/config/site-config'
import { Document } from '@contentful/rich-text-types'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { reWrap } from '@/utils/reWrap'

type EpisodeBaseType = Queries.ContentfulPodcastEpisodeBySlugQuery['contentfulPodcastEpisode']
type RawStringContainer = { raw: string }

interface Props {
  title: string
  description: RawStringContainer
  pubDate: string
  url: string
  contentUrl: string
  duration: number
  hosts: EpisodeBaseType['hosts']
}

export function getJsonLd({ title, description, pubDate, url, contentUrl, duration, hosts }: Props) {
  return {
    '@context': 'https://schema.org',
    '@type': 'PodcastEpisode',
    name: title,
    url: `${siteUrl}${url}`,
    datePublished: pubDate,
    description: makePlainStringFromRawContainer(description),
    partOfSeries: {
      '@type': 'PodcastSeries',
      name: 'SAMexpert Podcast',
      url: 'https://samexpert.com/podcast/',
    },
    publisher: {
      '@type': 'Organization',
      name: 'SAMexpert',
      url: 'https://samexpert.com/',
    },
    associatedMedia: {
      '@type': 'MediaObject',
      contentUrl,
      encodingFormat: 'audio/mpeg',
      duration: convertSecondsToISO8601Duration(duration),
    },
    creator: makeAuthors(hosts),
  }
}

function makePlainStringFromRawContainer(container: RawStringContainer): string {
  return documentToPlainTextString(reWrap(container) as Document).replaceAll('"', "'")
}

function makeAuthors(hosts: EpisodeBaseType['hosts']) {
  return hosts.map(host => ({
    '@type': 'Person',
    name: host.name,
    url: `${siteUrl}/team/${host.slug}`,
  }))
}

function convertSecondsToISO8601Duration(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let duration = 'P'
  if (hours > 0) {
    duration += `${hours}H`
  }
  if (minutes > 0) {
    duration += `${minutes}M`
  }
  if (remainingSeconds > 0 || duration === 'P') {
    duration += `${remainingSeconds}S`
  }
  return duration
}
