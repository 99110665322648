import React from 'react'

interface Props {
  size?: number
}
export const CloseIcon = ({ size = 16 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="currentColor">
      <path d="m9.452 8 6.248-6.249c.399-.399.399-1.053 0-1.452s-1.053-.399-1.452 0l-6.248 6.249-6.248-6.249c-.4-.399-1.053-.399-1.453 0s-.399 1.053 0 1.452l6.249 6.249-6.249 6.248c-.399.399-.399 1.053 0 1.452s1.053.399 1.452 0l6.249-6.248 6.249 6.249c.399.399 1.053.399 1.452 0s.399-1.053 0-1.452z" />
    </svg>
  )
}
