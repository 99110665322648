const siteConfig = {
  siteTitle: 'SAMexpert — Microsoft Cloud and Licensing Experts',
  siteDescription:
    'We solve your Microsoft licensing and Cloud Economics (FinOps) challenges. Cloud migration. Microsoft 365 optimisation. Microsoft negotiations. SPLA Audit defence.',
  trademark: 'SAMexpert',
  siteUrl: 'https://samexpert.com',
  social: {
    twitter: `samexpert`,
  },
  email: 'ask@samexpert.com',
  podcast: {
    path: 'podcast',
    title: 'SAMexpert Podcast',
    feeds: {
      apple: {
        name: 'Apple',
        url: 'https://podcasts.apple.com/us/podcast/samexpert-podcast/id1665297112'
      },
      /*
      youtube: {
        name: 'Youtube',
        url: 'https://www.youtube.com/playlist?list=PLrzGKxp1pvT93tf0phy2WDqq-gk1E8O5Q'
      },
      */
      spotify: {
        name: 'Spotify',
        url: 'https://open.spotify.com/show/2KailFjVs5nZiu52X6yzBw' 
      },
      rss: {
        name: 'RSS Feed',
        url: '/rss.xml'
      }
    }
  },
}

module.exports = siteConfig
