import React, { useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { graphql, useStaticQuery } from 'gatsby'
import { SinglePost } from './SinglePost'
import { ShowMoreBtn, SectionWrapper, Grid } from '../../../common'
import { tagState, postsBlocksCountState } from '../../state'

export const PostsGrid = () => {
  const data = useStaticQuery<Queries.blogPostsQueryQuery>(graphql`
    query blogPostsQuery {
      allContentfulPost(sort: { datetime: DESC }) {
        edges {
          node {
            id
            slug
            title
            datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
            featured
            excerpt {
              excerpt
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            }
            tags {
              tag
            }
            authors {
              name
              slug
              id
              miniavatar: avatar {
                gatsbyImageData(width: 30, layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `)

  const posts = data?.allContentfulPost?.edges
  const hasPosts = Array.isArray(posts)
  const { selectedTag } = useSnapshot(tagState)
  const { blocksCountOnScreen } = useSnapshot(postsBlocksCountState)
  const [blocksCountInc] = useState(blocksCountOnScreen)

  const { filteredFeaturedPosts, filteredOrdinaryPosts, countOfFeaturedBlocks, countOfOrdinaryBlocks } = useMemo(() => {
    if (!Array.isArray(posts)) return { filteredPosts: [] }

    const filteredByTagPosts = posts.filter(({ node }) => {
      if (selectedTag === null) return true

      const { tags } = node
      const tagsArray = tags?.map(({ tag }) => tag) ?? []

      return tagsArray.includes(selectedTag)
    })

    const filteredFeatured = filteredByTagPosts.filter(({ node }) => node.featured)
    const filteredOrdinary = filteredByTagPosts.filter(({ node }) => !node.featured)

    const featuredBlocks = filteredFeatured.length * 2
    const ordinaryBlocks = filteredOrdinary.length

    return {
      filteredFeaturedPosts: filteredFeatured,
      filteredOrdinaryPosts: filteredOrdinary,
      countOfFeaturedBlocks: featuredBlocks,
      countOfOrdinaryBlocks: ordinaryBlocks,
    }
  }, [posts, selectedTag])

  const { postsToShow, showLoadMoreBtn } = useMemo(() => {
    const featuredPostsOnScreen =
      countOfFeaturedBlocks < blocksCountOnScreen
        ? filteredFeaturedPosts
        : filteredFeaturedPosts.slice(0, blocksCountOnScreen / 2)
    const restOfOrdinaryBlocks = blocksCountOnScreen - countOfFeaturedBlocks
    const ordinaryPostsOnScreen = restOfOrdinaryBlocks > 0 ? filteredOrdinaryPosts.slice(0, restOfOrdinaryBlocks) : []

    const postsOnScreen = [...featuredPostsOnScreen, ...ordinaryPostsOnScreen]
    const countOfAllBlocks = countOfFeaturedBlocks + countOfOrdinaryBlocks

    return { postsToShow: postsOnScreen, showLoadMoreBtn: countOfAllBlocks > blocksCountOnScreen }
  }, [blocksCountOnScreen, countOfFeaturedBlocks, countOfOrdinaryBlocks, filteredFeaturedPosts, filteredOrdinaryPosts])

  const loadMorePosts = () => {
    postsBlocksCountState.blocksCountOnScreen += blocksCountInc
  }

  if (!hasPosts) return null

  return (
    <SectionWrapper $noMobilePadding>
      <Grid>
        {postsToShow.map(({ node }) => (
          <SinglePost key={node.id} post={node} />
        ))}
      </Grid>
      {showLoadMoreBtn && <ShowMoreBtn onButtonClick={loadMorePosts} />}
    </SectionWrapper>
  )
}
