import React from 'react'
import { useGetMenuItems } from '../useGetMenuItems'
import { MobGroup } from './MobGroup'

export const MenuListMob = () => {
  const menuItems = useGetMenuItems()
  return (
    <>
      {menuItems.map(item => (
        <MobGroup key={`${item.url}_${item.title}`} item={item} />
      ))}
    </>
  )
}
