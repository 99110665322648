import React from 'react'
import { styled } from '@linaria/react'
import { Wrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { Feeds } from './components'

export const ServicesBlock = () => {
  return (
    <Container $noMobilePadding={false}>
      <Feeds />
      {/*<Spacer />*/}
      {/*<Subscribe />*/}
    </Container>
  )
}

const Container = styled(Wrapper)`
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  flex-direction: column;

  ${desktopSmallMedia} {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
`

const BorderStyle = `1px dashed ${colors.tertiary.tertiary}`

const Spacer = styled.div`
  opacity: 0.75;
  width: 100%;
  margin: 32px 0;
  height: 1px;
  border-bottom: ${BorderStyle};

  ${desktopSmallMedia} {
    min-height: 48px;
    height: 100%;
    margin: 0 32px;
    width: 1px;
    border-bottom: none;
    border-left: ${BorderStyle};
  }
`
