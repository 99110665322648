import React from 'react'
import { css } from '@linaria/core'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { HighlightType } from '../types'
import { AspectRatioContainer, TextLink } from '@/UI'
import { colors, desktopLargeMedia, desktopSmallMedia, mobileModernMedia } from '@/lib/theme'
import { HighlightGatsbyImage, highlightTitleStyles } from '@/blocks/highlights'

interface Props {
  data: HighlightType
  isLast: boolean
}

export const HighlightSecondaryItem = ({ data, isLast }: Props) => {
  const { icon, title, subtitle, url } = data

  return (
    <Link to={url} className={WrapperStyle}>
      <Root $hasDivider={!isLast}>
        <ImageBlock>
          <HighlightGatsbyImage alt={title} image={icon.gatsbyImageData} />
        </ImageBlock>
        <TextsBlock>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <TextLinkStyled dangerouslySetInnerHTML={{ __html: subtitle }} />
        </TextsBlock>
      </Root>
    </Link>
  )
}

const WrapperStyle = css`
  display: grid;
`

const Root = styled.div<{ $hasDivider: boolean }>`
  display: grid;
  grid-gap: 12px;
  max-width: 320px;
  position: relative;
  grid-template-rows: 104px auto;
  justify-items: center;

  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    width: 0;
    right: -24px;
    height: 100%;
    border-left: 1px dashed ${colors.tertiary.tertiary};
  }

  &:after {
    content: '';
    display: ${({ $hasDivider }) => ($hasDivider ? 'block' : 'none')};
    position: absolute;
    left: 0;
    bottom: -24px;
    width: 100%;
    border-bottom: 1px dashed ${colors.tertiary.tertiary};
  }

  ${mobileModernMedia} {
    grid-template-columns: 128px auto;
    grid-template-rows: unset;
    justify-items: start;

    &:after {
      display: none;
    }
  }

  ${desktopSmallMedia} {
    max-width: unset;
    grid-template-columns: unset;
    grid-template-rows: 104px auto;
    justify-items: center;

    &:before {
      display: ${({ $hasDivider }) => ($hasDivider ? 'block' : 'none')};
    }
  }

  ${desktopLargeMedia} {
    grid-template-rows: unset;
    width: 100%;
    max-width: unset;
    grid-template-columns: 128px auto;
    justify-items: unset;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
`

const TextsBlock = styled.div`
  display: grid;
  grid-gap: 12px;
  justify-items: center;
  grid-template-rows: auto 24px;

  ${mobileModernMedia} {
    align-self: center;
    justify-items: left;
  }

  ${desktopSmallMedia} {
    align-self: unset;
    justify-items: center;
  }

  ${desktopLargeMedia} {
    justify-items: left;
  }
`

const Title = styled.h2`
  ${highlightTitleStyles};
  text-align: center;

  ${mobileModernMedia} {
    text-align: start;
  }

  ${desktopSmallMedia} {
    text-align: center;
  }

  ${desktopLargeMedia} {
    text-align: start;
  }
`

const TextLinkStyled = styled(TextLink)`
  align-items: center;
  text-align: center;

  ${mobileModernMedia} {
    text-align: start;
  }

  ${desktopSmallMedia} {
    text-align: center;
    justify-self: center;
  }

  ${desktopLargeMedia} {
    text-align: start;
    justify-self: unset;
  }
`

const ImageBlock = styled(AspectRatioContainer)`
  width: 104px;
  align-self: start;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.secondary.secondary};

  ${mobileModernMedia} {
    width: 128px;
  }

  ${desktopSmallMedia} {
    width: 104px;
  }

  ${desktopLargeMedia} {
    width: 128px;
  }
`
