import React, { Fragment } from 'react'
import StepsWrapper from '~/components/service/stepswrapper'
import StepNumber from '~/components/service/stepnumber'
import StepConnector from '~/components/service/stepconnector'
import StepText from '~/components/service/steptext'
import StepHeading from '~/components/service/stepheading'
import StepSubTitle from '~/components/service/stepsubtitle'
import StepTitle from '~/components/service/steptitle'
import SubSteps from '~/components/service/substeps'

const Steps = ({ steps }) => {
  return (
    <StepsWrapper steps={steps.length}>
      {steps.map((step, index) => {
        return (
          <Fragment key={`${step.stepId}_${index}`}>
            <StepNumber index={index}>{index + 1}</StepNumber>
            <StepHeading index={index}>
              <StepTitle>{step.name}</StepTitle>
              {step.subtitle && <StepSubTitle>{step.subtitle}</StepSubTitle>}
            </StepHeading>
            <StepText index={index}>
              {step.substeps && (
                <SubSteps>
                  {step.substeps.map((substep, i) => {
                    return <li key={i}>{substep}</li>
                  })}
                </SubSteps>
              )}
            </StepText>
            {index < steps.length - 1 && <StepConnector index={index}>&nbsp;</StepConnector>}
          </Fragment>
        )
      })}
    </StepsWrapper>
  )
}

export default Steps