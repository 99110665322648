import React from 'react'
import { styled } from '@linaria/react'
import { Episode } from '../types'
import { RegularEpisode } from './RegularEpisode'

interface Props {
  data: Episode[]
}

export const RegularEpisodesList = ({ data }: Props) => {
  return (
    <Container>
      {data.map(podcast => (
        <RegularEpisode key={podcast.id} data={podcast} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 32px;
`
