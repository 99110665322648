import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { AspectRatioContainer } from '@/UI'

interface Props {
  image: IGatsbyImageData
  alt: string
  isFeatured: boolean
}

export const ArticleImage = ({ image, alt, isFeatured }: Props) => {
  if (isFeatured)
    return (
      <ContainerBg>
        <GatsbyImage alt={alt} image={image} />
      </ContainerBg>
    )

  return (
    <Container verticalRatio="60%">
      <ImageContainer>
        <GatsbyImage alt={alt} image={image} />
      </ImageContainer>
    </Container>
  )
}

const Container = styled(AspectRatioContainer)`
  margin: 16px 16px 10px 16px;
  width: calc(100% - 32px);
`

const ImageContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
  }
`

const ContainerBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
`
