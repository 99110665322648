import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'

export type H1VariantType = 'Large' | 'XXL'

interface Props {
  $variant?: H1VariantType
  $isInverse?: boolean
}

export const TitleMain = styled.h1<Props>`
  font-family: ${fontLeagueSpartan};
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regular)};

  ${media.desktopSmall} {
    line-height: 1.05;
    font-size: ${({ $variant = 'Large' }) => ($variant === 'Large' ? 60 : 76)}px;
    font-weight: ${({ $variant = 'Large' }) => ($variant === 'Large' ? 600 : 500)};
    letter-spacing: ${({ $variant = 'Large' }) => ($variant === 'Large' ? '-0.01em' : '-0.015em')};
  }
`
