import { useCallback } from 'react'
import { useSnapshot } from 'valtio'
import axios, { Response } from 'redaxios'
import { formDataState, setSendingState, formNewsletterConsent } from './formState'

const NO_PATH_ERROR = 'NO_PATH_ERROR'

interface ResponseData {
  ok: boolean
}

const url = 'https://t7wva4k938.execute-api.eu-west-2.amazonaws.com/dev/email/send'
const textResultError =
  "There was an error while sending your message. Please bear with us while we're looking into it."

export function useFormSubmitHandler() {
  const { isValid, formData, resetFields } = useSnapshot(formDataState)
  const { agree } = useSnapshot(formNewsletterConsent)
  const currentPathDraft = typeof window !== 'undefined' ? window.location?.pathname : NO_PATH_ERROR

  return useCallback(async () => {
    if (!isValid) return

    const patchedFormData = {
      ...formData,
      firstName: formData.name,
      lastName: formData.name,
      fullName: formData.name,
      newsletterConsent: agree,
      currentPath: currentPathDraft || NO_PATH_ERROR,
    }

    try {
      setSendingState('isPending')
      const response: Response<ResponseData> = await axios.post(url, patchedFormData)
      //
      // const response = await new Promise<ResponseData>((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve({ ok: true })
      //     // reject(new Error('Shit happens'))
      //   }, 500)
      // })
      //

      // @ts-ignore
      if (!response.ok) throw new Error(textResultError)
      setSendingState('isFulfilled')
    } catch (error) {
      setSendingState('isError')
      console.error(error)
    } finally {
      resetFields()
      formNewsletterConsent.agree = false

      setTimeout(() => {
        setSendingState('initial')
      }, 1200)
    }
  }, [formData, isValid, resetFields])
}
