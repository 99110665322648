import React from 'react'
import { Link } from 'gatsby'
import { SingleLinkContent, AllLinksWrapper } from './AllLinksWrapper'

export const Pages = () => {
  return (
    <AllLinksWrapper gridArea="pages">
      <h2>Company</h2>
      <Link to="/contact/">
        <SingleLinkContent>Contact us</SingleLinkContent>
      </Link>
      <Link to="/team/">
        <SingleLinkContent>People</SingleLinkContent>
      </Link>
      <Link to="/global-worldwide-consultancy/">
        <SingleLinkContent>Territory</SingleLinkContent>
      </Link>
      <Link to="/alexander-golev-consultant-of-the-year-2021-itam-review-excellence-awards/">
        <SingleLinkContent>Awards</SingleLinkContent>
      </Link>
    </AllLinksWrapper>
  )
}
