import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { siteUrl } from '_/config/site-config'
import { colors, fontLeagueSpartan, fontLibreBaskerville, media } from '@/lib/theme'
import { Button, IconSocial, textLinkStyles } from '@/UI'
import { useBookACall } from '@/hooks'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import { Styleable } from '@/lib/interfaces'

export interface Author {
  name: string
  slug: string
  linkedIn: string | null
  twitter: string | null
  role: string | null
  bookACall?: string | null
  bio: {
    bio: string
  }
  avatar?: {
    gatsbyImageData: IGatsbyImageData
  } | null
}

interface Props extends Styleable {
  author: Author
}

export const AuthorCard = ({ author, className }: Props) => {
  const { avatar, name, role, bio, slug, twitter, linkedIn, bookACall } = author
  const bookACallHandler = useBookACall(bookACall)

  return (
    <AuthorCardView className={className}>
      {avatar && (
        <ImageBlock>
          <GatsbyImage image={avatar.gatsbyImageData} alt={name} />
        </ImageBlock>
      )}
      <Content>
        <Link to={`${siteUrl}/team/${slug}`}>
          <Name>{name}</Name>
        </Link>
        {role && <Role>{role}</Role>}
        <Bio>{bio.bio}</Bio>
        <InteractiveContent>
          <ButtonsBlock>
            <Link to={`${siteUrl}/contact/`}>
              <Button preventDefaultClick={false} type="secondary">
                Contact
              </Button>
            </Link>
            <Button onClick={bookACallHandler} type="secondary">
              Book a call
            </Button>
          </ButtonsBlock>
          <SocialBlock>
            {linkedIn && (
              <IconSocial type="LinkedIn" urlAdd={`in/${linkedIn}`} size={24} fillBg={colors.secondary.secondary} />
            )}
            {twitter && (
              <IconSocial
                type="Twitter"
                urlAdd={twitter}
                size={32}
                fillContent={colors.secondary.secondary}
                fillBg={colors.transparent}
                interactiveOn="content"
              />
            )}
          </SocialBlock>
        </InteractiveContent>
      </Content>
    </AuthorCardView>
  )
}

const AuthorCardView = styled.div`
  display: grid;
  background-color: ${colors.background.grayLightest};
  color: ${colors.secondary.darkest};
  border-radius: 16px;
  overflow: hidden;
  min-height: 277px;
  max-width: 392px;

  ${media.desktopSmall} {
    max-width: unset;
    grid-template-columns: 196px auto;
  }

  ${media.print} {
    min-height: unset;
  }
`

const ImageBlock = styled.div`
  display: flex;
  width: 100%;
  height: 250px;

  ${media.desktopSmall} {
    max-width: 196px;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${media.mobileModern} {
    padding: 24px;
  }

  ${media.desktopSmall} {
    padding: 32px;
  }
`

const Name = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01em;
  ${textLinkStyles};
`

const Role = styled.span`
  font-family: ${fontLibreBaskerville};
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 163%; /* 26.08px */
  margin-top: 4px;
  opacity: 0.5;
`

const Bio = styled.div`
  font-family: ${fontLibreBaskerville};
  font-size: 14px;
  font-weight: 400;
  line-height: 163%;
  margin-top: 16px;

  ${media.desktopSmall} {
    margin-bottom: auto;
  }
`

const InteractiveContent = styled.div`
  display: grid;
  grid-gap: 24px;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  ${doNotPrintThisBlock};

  ${media.mobileModern} {
    margin-top: 24px;
  }

  ${media.desktopSmall} {
    justify-content: start;
    grid-auto-flow: column;
  }
`

const ButtonsBlock = styled.div`
  display: grid;
  grid-row-gap: 16px;

  ${media.desktopSmall} {
    grid-column-gap: 10px;
    grid-auto-flow: column;
  }
`

const SocialBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-self: start;
  align-items: center;
`
