import { proxy } from 'valtio'
import { derive } from 'valtio/utils'

export const dropdownState = proxy<{ visibleDropdown: string | null }>({ visibleDropdown: null })
export const showDropdown = (id: string) => {
  dropdownState.visibleDropdown = id
}
export const hideDropdown = () => {
  dropdownState.visibleDropdown = null
}

export const navState = proxy<{ isNavVisible: boolean }>({ isNavVisible: false })
export const showNav = () => {
  navState.isNavVisible = true
}
export const hideNav = () => {
  navState.isNavVisible = false
}
export const toggleNav = () => {
  if (navState.isNavVisible) {
    hideNav()
  } else {
    showNav()
  }
}

export const mobSearchState = proxy<{ isSearchVisible: boolean }>({ isSearchVisible: false })
export const showSearch = () => {
  mobSearchState.isSearchVisible = true
}
export const hideSearch = () => {
  mobSearchState.isSearchVisible = false
}

export const searchQueryState = proxy<{ query: string }>({ query: '' })
export const setSearchQuery = (query: string) => {
  searchQueryState.query = query
}
export const resetSearchQuery = () => {
  searchQueryState.query = ''
}
export const isHasSearchQuery = derive({
  value: get => Boolean(get(searchQueryState).query.length),
})

export const searchQueryHitsCount = proxy<{value: Record<string, boolean>}>({value: {}})
export const resetHitsCount = () => {
  searchQueryHitsCount.value = {}
}
export const hasSearchResults = derive({
  value: get => {
    const state = get(searchQueryHitsCount)
    return Object.values(state.value).includes(true)
  }
})

// export const unsub = devtools(hasSearchResults, { name: 'hasSearchResults', enabled: true })
