function slugify(node, options = { delimiter: '-', prefix: '' }) {
  // eslint-disable-next-line no-param-reassign
  if (!options.delimiter) options.delimiter = '-'
  // eslint-disable-next-line no-param-reassign
  if (!options.prefix) options.prefix = ''

  if (!node || typeof node === 'boolean') {
    return ''
  }

  const { delimiter, prefix } = options

  // string, number
  if (typeof node === 'string' || typeof node === 'number') {
    const harmonizedPrefix = harmonize(prefix, delimiter, true)
    const harmonizedNode = harmonize(String(node), delimiter)

    if (harmonizedPrefix) {
      return `${harmonizedPrefix}${delimiter}${harmonizedNode}`
    }

    return harmonizedNode
  }

  // empty object
  if (typeof node === 'object' && Object.keys(node).length === 0) {
    return ''
  }

  // ReactPortal
  if ('children' in node) {
    return slugify(node.children)
  }

  // ReactNodeArray
  if (node instanceof Array) {
    return slugify(node.map(subNode => slugify(subNode, { delimiter })).join(delimiter), options)
  }

  // ReactElement
  if ('type' in node) return slugify(node.props.children, options)

  // unhandled case
  return ''
}

module.exports = { slugify }

function stripAccents(input) {
  const accents =
    'ÀÁÂÃÄÅĄàáâãäåąÒÓÔÕÕÖØòóôõöøÈÉÊËĘèéêëðęÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠŚšśŸÿýŽŹŻžźżŁłŃńàáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ'
  const fixes =
    'AAAAAAAaaaaaaaOOOOOOOooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNnSSssYyyZZZzzzLlNnaaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy'
  const split = accents.split('').join('|')
  const reg = new RegExp(`(${split})`, 'g')

  function replacement(a) {
    return fixes[accents.indexOf(a)] || ''
  }

  return input.replace(reg, replacement)
}

function getSafeRegexpString(input) {
  return input
    .split('')
    .map(char => `\\${char}`)
    .join('')
}

function harmonize(input, delimiter, ignoreInvalid = false) {
  const harmonized = stripAccents(input).trim().toLowerCase()
  const safeDelimiter = getSafeRegexpString(delimiter)

  if (ignoreInvalid) {
    return harmonized.replace(/\s+/g, delimiter)
  }

  return harmonized
    .replace(new RegExp(`[^a-z0-9${safeDelimiter}]+`, 'g'), delimiter) // Replace all non-valid caracters by delimiter
    .replace(new RegExp(`${safeDelimiter}+`, 'g'), delimiter) // Remove multiple delimiters repetition
    .replace(new RegExp(`^${safeDelimiter}`, 'g'), '') // remove delimiter at the beginning
    .replace(new RegExp(`${safeDelimiter}$`, 'g'), '') // remove delimiter at the end
}
