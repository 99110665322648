import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Wrapper } from '@/UI'

interface Props {
  title: string
  children?: ReactNode
}

export const PageHeading = ({ title, children }: Props) => {
  return (
    <Container $noMobilePadding={false}>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}

const Container = styled(Wrapper)`
  display: grid;
  grid-row-gap: 16px;
`

const Title = styled.h1`
  font-weight: 300;
  font-size: 34px;
  line-height: 106%;
  letter-spacing: -0.02em;
`
