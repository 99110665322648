import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'

interface Props {
  children: ReactNode
  gridArea: string
}

export const AllLinksWrapper = ({ children, gridArea }: Props) => {
  return <Container gridArea={gridArea}>{children}</Container>
}

const Container = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
  display: grid;
  justify-items: left;
  gap: 1em;
  width: 100%;

  & a {
    max-width: 100%;
  }
`

export const SingleLinkContent = styled.span`
  min-width: 48px;
  max-width: 100%;
  display: flex;
  white-space: normal;
`
