import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'

const defaultSize = 36

interface TitleProps {
  $isInverted?: boolean
  $gap?: number
  $gapMob?: number
  $isCenteredOnMob?: boolean
  $isSlim?: boolean
  $customSize?: number
  $customSizeMobile?: number
}

export const TitleH2 = styled.h2<TitleProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${fontLeagueSpartan};
  font-size: ${({ $customSizeMobile }) => $customSizeMobile ?? defaultSize}px;
  font-style: normal;
  font-weight: ${({ $isSlim }) => ($isSlim ? 400 : 700)};
  line-height: 110%;
  letter-spacing: -0.01em;
  margin-bottom: ${({ $gap, $gapMob }) => {
    if (typeof $gapMob === 'number') return $gapMob
    if (typeof $gap === 'number') return $gap
    return 24
  }}px;
  color: ${({ $isInverted }) => (!$isInverted ? colors.secondary.secondary : colors.white)};
  align-self: ${({ $isCenteredOnMob }) => ($isCenteredOnMob ? 'center' : 'start')};

  ${desktopSmallMedia} {
    font-size: ${({ $customSize }) => $customSize ?? defaultSize}px;
    align-self: start;
    margin-bottom: ${({ $gap }) => $gap ?? 24}px;
  }
`
