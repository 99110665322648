import React from 'react'
import { styled } from '@linaria/react'
import dayjs from 'dayjs'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { colors, tabletMedia } from '@/lib/theme'
import { ArticleImage } from './ArticleImage'
import { boxShadow, ArticleAuthors, AuthorType } from '@/UI'
import { PostType } from '../../types'

interface Props {
  post: PostType
}

export const SinglePost = ({ post }: Props) => {
  const data = useStaticQuery<Queries.blogDefaultImageQueryQuery>(graphql`
    query blogDefaultImageQuery {
      defaultImage: contentfulDefaultImage(for: { eq: "Page" }) {
        image {
          fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
        }
      }
    }
  `)

  const { defaultImage } = data

  const { title, authors, datetime, slug, tags, excerpt, featured, image } = post

  const tagFinal = Array.isArray(tags) && tags[0].tag
  const titleFinal = title ?? slug
  const textFinal = excerpt?.childMarkdownRemark?.html
  const authorsFinal = authors?.map(
    ({ name, miniavatar }) => ({ name, img: miniavatar.gatsbyImageData } as unknown as AuthorType),
  )
  const dateTimeFinal = datetime ? dayjs(datetime).format('DD.MM.YYYY') : null
  const imageFinal = image?.fluid ?? defaultImage.image.fluid

  return (
    <Container className={boxShadow} isFeatured={featured}>
      <Link to={`/${slug}/`}>
        <ArticleImage image={imageFinal} alt={titleFinal} isFeatured={featured} />
        <ContentContainer isFeatured={featured}>
          {tagFinal && <Tags>{tagFinal}</Tags>}
          <Title isWhite={featured}>{titleFinal}</Title>
          {textFinal && <Text isWhite={featured} dangerouslySetInnerHTML={{ __html: textFinal }} />}
          <BottomContainer isFeatured={featured}>
            {Boolean(datetime) && <DateTime>{dateTimeFinal}</DateTime>}
            <ArticleAuthors authors={authorsFinal} />
          </BottomContainer>
        </ContentContainer>
      </Link>
    </Container>
  )
}

const Container = styled.div<{ isFeatured: boolean }>`
  position: relative;
  background-color: #fff;
  overflow: hidden;
  z-index: 0;
  min-height: ${({ isFeatured }) => (isFeatured ? '470px' : 'none')};
  border-radius: ${({ isFeatured }) => (isFeatured ? 0 : '4px')};
  margin: ${({ isFeatured }) => (isFeatured ? 0 : '0 16px')};

  & > a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  ${tabletMedia} {
    grid-column: auto / span ${({ isFeatured }) => (isFeatured ? 2 : 1)};
    border-radius: 4px;
    margin: 0;
  }
`

const ContentContainer = styled.div<{ isFeatured: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: ${({ isFeatured }) => (isFeatured ? '0 32px 32px 32px' : '0 16px 16px 16px')};
  flex-grow: ${({ isFeatured }) => (isFeatured ? 0 : 1)};
  margin-top: ${({ isFeatured }) => (isFeatured ? 'auto' : 'none')};
  padding-top: ${({ isFeatured }) => (isFeatured ? '16px' : 0)};
  background: ${({ isFeatured }) =>
    isFeatured ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 30.73%)' : 'none'};

  ${tabletMedia} {
    padding: 0 16px 16px 16px;
  }
`

const Tags = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: ${colors.tertiary.tertiary};
  margin-bottom: 4px;
`

const Title = styled.h2<{ isWhite: boolean }>`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 16px;
  color: ${({ isWhite }) => (isWhite ? '#fff' : '#000')};
`

const Text = styled.div<{ isWhite: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  margin-bottom: 16px;
  color: ${({ isWhite }) => (isWhite ? '#fff' : '#000')};
`

const BottomContainer = styled.div<{ isFeatured: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${({ isFeatured }) => (isFeatured ? 'none' : 'auto')};
`
const DateTime = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.004em;
  color: ${colors.tertiary.tertiary};
  height: 16px;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${colors.tertiary.tertiary};
`
