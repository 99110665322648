import React from 'react'
import { styled } from '@linaria/react'
import { Wrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { TitleSecond } from '~/features/podcast/components/common'
import { EpisodeSinglePage } from '../types'
import { Hosts } from '@/blocks/Hosts'
import { RichTextImproved } from '@/blocks/rich-text-improoved'

interface Props extends Pick<EpisodeSinglePage, 'transcript' | 'hosts'> {}

export const ExtTranscript = ({ transcript, hosts }: Props) => {
  return (
    <Wrapper $noMobilePadding={false}>
      <Container>
        <InfoBlock>
          <TitleSecond text="Episode Transcript" />
          <Hosts hosts={hosts} />
        </InfoBlock>
        <Texts content={transcript} parseReferences />
      </Container>
    </Wrapper>
  )
}

const Container = styled.div`
  display: grid;
  background-color: ${colors.background.grayLightest};
  grid-row-gap: 32px;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 48px;

  ${desktopSmallMedia} {
    grid-template-columns: 256px auto;
    grid-column-gap: 24px;
    padding: 46px;
    margin-bottom: 56px;
  }
`

const Texts = styled(RichTextImproved)``

const InfoBlock = styled.div`
  display: grid;
  grid-row-gap: 32px;

  ${desktopSmallMedia} {
    grid-gap: 48px;
    align-self: start;
  }
`
