import React from 'react'
import {
  Form,
  Field,
  // useField
} from 'react-final-form'
import { styled } from '@linaria/react'
import { Validators } from '@lemoncode/fonk'
import { createFinalFormValidation } from '@lemoncode/fonk-final-form'
import { FORM_ERROR } from 'final-form'
import { useLocation } from '@reach/router'
import { theme } from '../gatsby-plugin-theme-ui/index'
import { css } from '@linaria/core'

const url = 'https://t7wva4k938.execute-api.eu-west-2.amazonaws.com/dev/email/send'

const onSubmit = async values => {
  setTimeout(() => {
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values, null, 2),
    })
      .then(response => {
        if (response.ok) {
          return { success: 'Thank you! We will get in touch with you ASAP' }
        }
        return {
          [FORM_ERROR]:
            "There was an error while sending your message. Please bear with us while we're looking into it.",
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(`Fetch error: ${error}`)
        //alert("There was an error while sending your message. Please bear with us while we're looking into it.")
      })
  }, 400)
}

const validationSchema = {
  field: {
    firstName: [Validators.required.validator],
    lastName: [Validators.required.validator],
    email: [Validators.required.validator, Validators.email.validator],
    message: [Validators.required.validator],
  },
}

const formValidation = createFinalFormValidation(validationSchema)

const Wrapper = styled.div`
  width: 100%;
  & input,
  textarea {
    display: block;
    width: 100%;
    border: 1px solid black;
    padding: 0.5em;
    font-size: 1em;
  }
  & input {
    height: 3em;
  }
  & input,
  textarea,
  button {
    border-radius: 4px;
  }
  & div.error {
    padding: 0.5em 0;
    font-weight: 500;
    color: ${theme.colors.primary};
  }
  & div.success {
    padding: 0.5em 0;
    font-weight: 500;
  }
  & button {
    color: white;
    padding: 0.75em;
  }
  & button.enabled {
    background-color: ${theme.colors.primary};
  }
  & button.disabled {
    background-color: ${theme.colors.muted};
  }
  & div.field {
    margin-bottom: 1em;
  }
  & label {
    font-weight: 500;
  }
`

const ContactForm = ({ style, className }) => {
  const location = useLocation()

  return (
    <Wrapper style={style} className={className}>
      <Form
        initialValues={{ firstName: '', lastName: '', email: '', message: '' }}
        onSubmit={onSubmit}
        validate={values => formValidation.validateForm(values)}
        render={({ handleSubmit, submitting, pristine, submitError, form, submitSucceeded, values }) => (
          <form
            onSubmit={async event => {
              const promise = await handleSubmit(event)?.then(() => {
                Object.keys(values).forEach(field => {
                  form.resetFieldState(field)
                  form.change(field, undefined)
                })
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'formSubmission',
                  formType: 'Contact Form',
                  url: location ? location.pathname : 'undefined',
                })
              })
              return promise
            }}
          >
            <Field name="firstName">
              {({ input, meta }) => (
                <div className="field">
                  <label htmlFor="firstName">Your first name *</label>
                  <input {...input} type="text" placeholder="First name" className={ActionStyles} />
                  {meta.error && meta.touched && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
            <Field name="lastName">
              {({ input, meta }) => (
                <div className="field">
                  <label htmlFor="lastName">Your last name *</label>
                  <input {...input} type="text" placeholder="Last name" />
                  {meta.error && meta.touched && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
            <Field name="email">
              {({ input, meta }) => (
                <div className="field">
                  <label htmlFor="email">Your email address *</label>
                  <input {...input} type="text" placeholder="Email" />
                  {meta.error && meta.touched && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
            <Field name="message">
              {({ input, meta }) => (
                <div className="field">
                  <label htmlFor="message">Your challenges *</label>
                  <textarea {...input} rows={5} placeholder="Please tell us about your challenges" />
                  {meta.error && meta.touched && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
            {submitError && (
              <div id="submitError" className="error">
                {submitError}
              </div>
            )}
            {submitSucceeded && (
              <div id="submitSucceeded" className="success">
                Thank you. Your message has been sent.
              </div>
            )}
            <div id="buttons" className="buttons">
              <button
                id="contactFormSubmit"
                type="submit"
                disabled={submitting || pristine}
                className={submitting || pristine ? 'disabled' : 'enabled'}
              >
                {submitting ? 'Sending...' : 'Send to SAMexpert'}
              </button>
            </div>
          </form>
        )}
      />
    </Wrapper>
  )
}

const ActionStyles = css`
  &:hover {
    border: 1px solid green !important;
  }
  &:focus {
    border: 1px solid red !important;
  }
`

export default ContactForm
