import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, type IGatsbyImageData } from 'gatsby-plugin-image'
import { AspectRatioContainer } from '@/UI'
import { desktopSmallMedia } from '@/lib/theme'

type CoverType = 'main' | 'listItem' | 'single'

interface EpisodeImage {
  gatsbyImageData: IGatsbyImageData
  title: string
}

interface Props {
  image: EpisodeImage
  coverType?: CoverType
}

export const BlockCover = ({ image, coverType = 'listItem' }: Props) => {
  const { title, gatsbyImageData } = image

  return (
    <Container coverType={coverType}>
      <AspectRatioContainer>
        <GatsbyImage alt={title} image={gatsbyImageData} />
      </AspectRatioContainer>
    </Container>
  )
}

const Container = styled.div<{ coverType: CoverType }>`
  order: ${({ coverType }) => (coverType === 'single' ? 0 : 'unset')};
  flex-shrink: 0;
  margin-left: auto;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  align-self: flex-start;
  display: ${({ coverType }) => (coverType === 'main' || coverType === 'single' ? 'flex' : 'none')};

  ${desktopSmallMedia} {
    order: ${({ coverType }) => (coverType === 'main' || coverType === 'listItem' ? 1 : 'unset')};
    display: flex;
    width: ${({ coverType }) => {
      switch (coverType) {
        case 'main':
          return 417
        case 'single':
          return 380
        default:
          return 252
      }
    }}px;
  }

  & img {
    object-fit: cover;
  }
`
