import React from 'react'
import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, hoverMedia, theme } from '@/lib/theme'
import { VideoType } from './types'
import { selectVideo } from './selectedVideoState'
import { VideoThumbnail } from './VideoThumbnail'

interface Props {
  video: VideoType
}

export const VideoAnnounce = ({ video }: Props) => {
  const { title, videoId, localThumbnail } = video

  return (
    <Root onClick={() => selectVideo(videoId)}>
      <VideoThumbnail picture={localThumbnail} alt={title} />
      <Title>{title}</Title>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${colors.secondary.secondary};
  padding: 24px;
  transition: box-shadow 0.2s ease-in-out;

  ${hoverMedia} {
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 8px 2px ${theme.hover};
    }
  }
`

const Title = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: ${colors.white};
  margin-top: 24px;
`
