import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia, //desktopLargeMedia
} from '@/lib/theme'

const StepHeading = styled.div`
  grid-area: ${props => 1 + 4 * props.index} / 4 / ${props => 4 + 4 * props.index} / 5;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  ${desktopSmallMedia} {
    grid-area: -2 / ${props => 1 + 5 * props.index} / -3 / ${props => 5 + 5 * props.index};
  }
`

export default StepHeading