import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia,
  desktopLargeMedia,
} from '@/lib/theme'

const StepsWrapper = styled.div`
  display: grid;
  grid-area: steps;
  width: 100%;
  padding: 32px 24px;
  grid-template-columns: 24px 24px 16px auto;
  grid-template-rows: repeat(${props => props.steps}, 24px 24px auto auto);
  ${desktopSmallMedia} {
    grid-template-columns: repeat(${props => props.steps - 1}, 1fr 24px 24px 1fr 24px) 1fr 24px 24px 1fr;
    grid-template-rows: 24px 24px 24px max-content max-content;
  }
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`

export default StepsWrapper