import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { theme } from '../gatsby-plugin-theme-ui/index'

const SectionWrapper = styled.div`
  & h2 {
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  & ul {
    margin-bottom: 2em;
  }
  & li {
    margin-bottom: 0.5em;
  }
  & a {
    color: #00c;
    font-weight: 500;
    transition: all 0.3s;
  }
  & a:hover {
    color: ${theme.colors.logoOrange};
  }
`

const SiteMapSection = ({ title, pages, prefix = '' }) => {
  const prefixWithSlashes = prefix.length > 0 ? `/${prefix}/` : '/'

  return (
    <SectionWrapper>
      <h2>{title}</h2>
      <ul>
        {pages.map(page => (
          <li key={page.slug}>
            <Link to={`${prefixWithSlashes}${page.slug}/`}>{page.title}</Link>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  )
}

export default SiteMapSection
