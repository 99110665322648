import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { styled } from '@linaria/react'
import { LightHero, Title, Excerpt, HeroSocial } from '../components/hero'
import Avatar from '../components/avatar'
import { RichText2 } from '../components/richtext'
import { SEO, Layout } from '@/blocks/layout'

const AuthorTemplate = ({ data }) => {
  const { name, role, linkedIn, avatar, twitter, longBio } = data.contentfulAuthor

  return (
    <Layout hasNoVerticalMargins>
      <Wrapper>
        <LightHero style={{ gridArea: 'hero' }}>
          <Avatar
            image={getImage(avatar)}
            alt={name}
            style={{
              gridArea: 'image',
              boxShadow: `0 0 1em 0em black`,
            }}
          />
          <Title>{name}</Title>
          <Excerpt>{role}</Excerpt>
          <HeroSocial linkedIn={linkedIn} twitter={twitter} />
        </LightHero>
        <LongBio style={{ gridArea: 'longbio', maxWidth: '48em' }} content={longBio} />
      </Wrapper>
    </Layout>
  )
}

const LongBio = ({ content, style }) => {
  return content && <RichText2 style={style} content={content} />
}

export default AuthorTemplate

export const Head = ({ data }) => {
  const { name, bio, avatar } = data.contentfulAuthor
  const seoImage = getImage(avatar.fixed).images.fallback.src

  return <SEO title={name} description={bio.childMarkdownRemark.excerpt} image={seoImage} />
}

export const pageQuery = graphql`
  query ContentfulAuthorBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAuthor(slug: { eq: $slug }) {
      id
      name
      linkedIn
      twitter
      website
      email
      role
      bio {
        bio
        childMarkdownRemark {
          html
          excerpt(format: PLAIN)
        }
      }
      longBio {
        raw
      }
      avatar {
        gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED, formats: [AUTO, WEBP], quality: 10)
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO, WEBP]
          quality: 10
        )
      }
    }
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template: 'hero' 'longbio';
  grid-template-columns: 100%;
`
