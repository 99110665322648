export const colors = {
  primary: {
    lightest: '#FFE1D3',
    light: '#FF9E7A',
    primary: '#FF7E4F',
    dark: '#E56A45',
    darkest: '#B15238',
  },
  secondary: {
    lightest: '#525050',
    secondary: '#272424',
    darkest: '#0E0D0E',
  },
  tertiary: {
    lightest: '#C3BDB6',
    tertiary: '#9E958F',
    darkest: '#7A726A',
  },
  outcome: {
    positive: {
      lightest: '#C4ECD0',
      positive: '#30C26A',
      darkest: '#279260',
    },
    negative: {
      lightest: '#FEDBD4',
      negative: '#E96C6C',
      darkest: '#D62B2B',
    },
  },
  background: {
    grayLightest: '#EBEAE8',
    gray: '#9E958F',
  },
  textStyles: {
    regular: '#272424',
    inverse: '#fff',
    regularTransparent: 'rgba(39, 36, 36, 0.80)',
    inverseTransparent: 'rgba(255, 255, 255, 0.70)',
  },
  white: '#fff',
  black: '#000',
  transparent: 'transparent',
} as const

export const theme = {
  hover: colors.primary.primary,
  active: colors.primary.darkest,
} as const

const mediaQuery = {
  mobileModern: 376,
  tablet: 640,
  desktopSmall: 1024,
  desktopMedium: 1280,
  desktopLarge: 1440,
} as const

type MqType = keyof typeof mediaQuery
type AdditionalMediaType = 'hover' | 'notHover' | 'print'

const getMq = (mq: MqType) => `(min-width: ${mediaQuery[mq]}px)`
const getMedia = (mq: MqType) => `@media screen and (min-width: ${mediaQuery[mq]}px)`

export const mqMobileModern = `(min-width: ${mediaQuery.mobileModern}px)`
export const mqTabletMedia = `(min-width: ${mediaQuery.tablet}px)`
export const mqDesktopSmall = `(min-width: ${mediaQuery.desktopSmall}px)`
export const mqDesktopMedium = `(min-width: ${mediaQuery.desktopMedium}px)`
export const mqDesktopLarge = `(min-width: ${mediaQuery.desktopLarge}px)`

export const mq: Record<MqType, string> = {
  mobileModern: getMq('mobileModern'),
  tablet: getMq('tablet'),
  desktopSmall: getMq('desktopSmall'),
  desktopMedium: getMq('desktopMedium'),
  desktopLarge: getMq('desktopLarge'),
}

export const media: Record<MqType | AdditionalMediaType, string> = {
  mobileModern: getMedia('mobileModern'),
  tablet: getMedia('tablet'),
  desktopSmall: getMedia('desktopSmall'),
  desktopMedium: getMedia('desktopMedium'),
  desktopLarge: getMedia('desktopLarge'),
  hover: '@media (hover: hover)',
  notHover: '@media (hover: none)',
  print: '@media print'
}

export const mobileModernMedia = `@media screen and ${mqMobileModern}`
export const tabletMedia = `@media screen and ${mqTabletMedia}`
export const desktopSmallMedia = `@media screen and ${mqDesktopSmall}`
export const desktopMediumMedia = `@media screen and ${mqDesktopMedium}`
export const desktopLargeMedia = `@media screen and ${mqDesktopLarge}`

export const notHoverMedia = '@media (hover: none)'
export const hoverMedia = '@media (hover: hover)'

export const printMedia = '@media print'

export const shadowStyle = '0 0 8px 2px rgba(0, 0, 0, 0.25)'
export const transition = 'box-shadow 0.3s ease-in-out'

export const fontInfer = `'Inter', sans-serif`
export const fontLibreBaskerville = `'Libre Baskerville', serif`
export const fontLeagueSpartan = `'League Spartan', sans-serif`
