import React from 'react'
import { styled } from '@linaria/react'
import { CSSProperties } from '@linaria/core'
import { graphql, useStaticQuery } from 'gatsby'
import { colors, fontLibreBaskerville, media } from '@/lib/theme'
import { Typography, Wrapper } from '@/UI'
import { formatPhoneNumber } from '@/utils/formatPhoneNumber'

interface Props {}

export const Locations = ({}: Props) => {
  const data = useStaticQuery<Queries.locationDataQuery>(graphql`
    query locationData {
      locations: allContentfulLocation(sort: { index: ASC }) {
        nodes {
          id
          addressCountry
          addressLocality
          addressRegion
          locationName
          postalCode
          streetAddress
          telephone
        }
      }
    }
  `)

  const locations = data.locations.nodes

  return (
    <Wrapper $noDesktopPadding>
      <LocationsView>
        {locations.map(
          ({
            id,
            locationName,
            addressLocality,
            addressRegion,
            streetAddress,
            addressCountry,
            telephone,
            postalCode,
          }) => (
            <Location key={id}>
              <Typography>
                <h3>{locationName}</h3>
              </Typography>
              <StreetAddress>
                {streetAddress} {addressLocality} {addressRegion} {postalCode} {addressCountry}
              </StreetAddress>
              <PhoneLink href={`tel:${formatPhoneNumber(telephone)}`}>{telephone}</PhoneLink>
            </Location>
          ),
        )}
      </LocationsView>
    </Wrapper>
  )
}

const LocationsView = styled.div`
  display: grid;
  grid-gap: 24px;
  padding-top: 32px;
  border-top: 2px solid ${colors.tertiary.lightest};
  margin: 0 auto;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.desktopSmall} {
    grid-template-columns: unset;
    margin: unset;
    padding-top: 48px;
  }

  ${media.desktopMedium} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Location = styled.div`
  display: grid;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
  grid-row-gap: 8px;

  ${media.desktopSmall} {
    padding: 32px;
    grid-row-gap: 16px;
  }
`

const ContactsStylesCommon: CSSProperties = {
  fontFamily: fontLibreBaskerville,
  fontWeight: 400,
  lineHeight: 1.63,
}

const StreetAddress = styled.span`
  ${ContactsStylesCommon};
  font-size: 14px;
`

const PhoneLink = styled.a`
  ${ContactsStylesCommon};
  font-size: 16px;
  text-decoration: underline;
  align-items: flex-end;
`
